import React from "react"
import { Box, Text } from "@chakra-ui/react"
import { SubScreenProps } from ".."
import { useTranslation } from "react-i18next"
import StandardButton from "@/components/ui/buttons/standard"
import { ENUM_BUTTON_VARIANTS } from "@/components/ui/buttons/standard/types"

export default function TerrorismDisclaimer({ advancePhase }: SubScreenProps) {
    const { t } = useTranslation(["onboarding", "common"])

    return (
        <Box w="full">
            <Text textStyle="InterTitle" mb="0.75rem">
                {t("terrorism.title")}
            </Text>
            <Text textStyle="InterSmall" mb="1.5rem">
                {t("terrorism.line1")}
            </Text>
            <Text textStyle="InterSmall" mb="1.5rem">
                {t("terrorism.line2")}
            </Text>
            <Text textStyle="InterSmall" mb="3rem">
                {t("terrorism.line3")}
            </Text>
            <StandardButton
                type="button"
                variant={ENUM_BUTTON_VARIANTS.DARK_PURPLE_PRIMARY}
                onClick={advancePhase}
                w="full"
            >
                {t("continue", { ns: "common" })}
            </StandardButton>
        </Box>
    )
}
