import React from "react"
import { Box, Link, Text } from "@chakra-ui/react"
import { SubScreenProps } from "screens/onboarding"
import { useTranslation } from "react-i18next"
import StandardButton from "components/ui/buttons/standard"
import { ENUM_BUTTON_VARIANTS } from "components/ui/buttons/standard/types"

export default function BeInTouch({ isLoading }: SubScreenProps) {
    const { t } = useTranslation(["onboarding", "common"])

    return (
        <Box w="full">
            <Text textStyle="InterTitle" mb="0.75rem">
                {t("extra.thankYou")}
            </Text>
            <Text textStyle="InterSmall" mb="0.75rem">
                {t("extra.inTouch1")}
            </Text>
            <Text>
                <Text as="span" textStyle="InterSmall">
                    {t("extra.inTouch2")}&nbsp;
                </Text>
                <Link
                    as="span"
                    href="mailto:businessonboarding@netcoins.com?subject=Pending Business Onboarding"
                    textStyle="InterSmall"
                    color="blue.100"
                >
                    businessonboarding@netcoins.com
                </Link>
            </Text>
            <StandardButton
                data-testid="continue-button"
                isLoading={isLoading}
                onClick={() => window.open("/logout", "_self")}
                type="button"
                variant={ENUM_BUTTON_VARIANTS.DARK_PURPLE_PRIMARY}
                w="full"
                mt="3rem"
            >
                {t("done", { ns: "common" })}
            </StandardButton>
        </Box>
    )
}
