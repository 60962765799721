import { Box, Table, TableContainer, Tbody, Text, Th, Thead, Tr, useToast } from "@chakra-ui/react"
import StandardButton from "@components/ui/buttons/standard"
import { ENUM_BUTTON_VARIANTS } from "@components/ui/buttons/standard/types"
import { useGetAssetsDetailsQuery } from "@redux/assetsDetails/apiSlice"
import { AssetDetails } from "@redux/assetsDetails/types"
import Hamburger from "assets/svgs/common/hamburger"
import Minus from "assets/svgs/common/minus"
import Plus from "assets/svgs/common/plus"
import Star from "assets/svgs/common/star"
import CoinTradingRow from "components/coins/tradingRow"
import Searchbar from "components/ui/searchbar"
import Tabs from "components/ui/tabs"
import ThSorting from "components/ui/thSorting"
import Echo from "laravel-echo"
import { debounce } from "lodash"
import * as pusher from "pusher-js"
import { useEffect, useMemo, useRef, useState } from "react"
import { useTranslation } from "react-i18next"
import DrawerCoinTrade from "./drawerCoinTrade"
import { PriceChangeData } from "./types"
import Search from "@assets/svgs/common/search"
import { useNavigate } from "react-router-dom"
import DashboardFooter from "@components/footer/dashboard"

window.Pusher = pusher

declare global {
    interface Window {
        Pusher: typeof pusher
        Echo: Echo
    }
}

const ECHO_OPTIONS = {
    broadcaster: "reverb",
    key: process.env.REACT_APP_REVERB_APP_KEY,
    wsHost: process.env.REACT_APP_REVERB_HOST,
    wsPort: process.env.REACT_APP_REVERB_PORT ?? 80,
    wssPort: process.env.REACT_APP_REVERB_PORT ?? 443,
    forceTLS: (process.env.REACT_APP_REVERB_SCHEME ?? "https") === "https",
    enabledTransports: ["ws", "wss"],
}

export default function Trade() {
    const { t } = useTranslation(["app", "common"])
    const toast = useToast()
    const navigate = useNavigate()

    const EchoClient = useRef<Echo>()
    const dataHashmap = useRef<{ [key: string]: AssetDetails }>({}) // hashmap representation of coin data. used to minimize state updates

    const [data, setData] = useState<AssetDetails[]>([]) // array representation of coin data
    const [displayedData, setDisplayedData] = useState<AssetDetails[]>([]) // sorted and filtered array representation of coin data
    const [tabIndex, setTabIndex] = useState(0)
    const [propertyAscending, setPropertyAscending] = useState<"price" | "24h_percent" | "market_cap" | null>(null)
    const [selectedCoin, setSelectedCoin] = useState<AssetDetails | null>(null)

    const { data: assetDetailsData, error } = useGetAssetsDetailsQuery()

    useEffect(() => {
        if (assetDetailsData) {
            dataHashmap.current = assetDetailsData
            const arr = Object.values(assetDetailsData).reduce((acc, curr) => {
                acc.push({
                    name: curr.name,
                    symbol: curr.symbol,
                    statistics: {
                        market_cap_cad: curr.statistics.market_cap_cad,
                        market_cap_usd: curr.statistics.market_cap_usd,
                    },
                    price: curr.price,
                    "24h_percent": curr["24h_percent"],
                })
                return acc
            }, [] as AssetDetails[])
            const sortedArr = sortData("market_cap", arr)
            setData(sortedArr)
            setDisplayedData(sortedArr)
            establishWebsocketConnection()
        }

        if (error) {
            toast({
                title: t("error.server", { ns: "common" }),
                description: t("error.fetch", { ns: "common" }),
                status: "error",
                duration: 50000,
            })
        }
    }, [assetDetailsData, error])

    // only runs if the EchoClient is initialized
    useEffect(() => {
        // batch up the changes into a single state update every 5s
        const interval = setInterval(() => {
            if (dataHashmap.current) {
                const arr = Object.values(dataHashmap.current) as AssetDetails[]
                const sortedArr = sortData("market_cap", arr)
                setData(sortedArr)
                // update currently displayed data with new price
                setDisplayedData((prev) => {
                    const newDisplayedData = prev.map((coin) => {
                        const updatedCoin = arr.find((c) => c.symbol === coin.symbol)
                        return updatedCoin ?? coin
                    })
                    return newDisplayedData
                })
            }
        }, 5000)

        // Cleanup socket
        return () => {
            clearInterval(interval)
            if (EchoClient.current) {
                EchoClient.current.disconnect()
                EchoClient.current = undefined
            }
        }
    }, [EchoClient.current])

    const filterData = (search: string, arr: AssetDetails[]) => {
        if (search === "") {
            setDisplayedData(arr)
        } else {
            const filtered = arr.filter((item) => {
                return (
                    item.name.toLowerCase().includes(search.toLowerCase()) ||
                    item.symbol.toLowerCase().includes(search.toLowerCase())
                )
            })
            setDisplayedData(filtered)
        }
    }

    const debounceResults = useMemo(() => {
        return debounce((search: string, arr: AssetDetails[]) => filterData(search, arr), 300)
    }, [])

    useEffect(() => {
        return () => {
            debounceResults.cancel()
        }
    }, [])

    const handleSearchChange = (text: string) => {
        debounceResults(text, data)
    }

    useEffect(() => {
        if (displayedData.length) {
            if (tabIndex === 1) {
                // TODO set displayedData based on favourites
            } else {
                setDisplayedData(data)
            }
        }
    }, [tabIndex])

    function establishWebsocketConnection() {
        if (EchoClient.current) return

        EchoClient.current = new Echo(ECHO_OPTIONS)
        for (const key in data) {
            const country = "cad" // TODO get country from user reducer
            const channel = EchoClient.current.channel("instrument." + key + country)
            // eslint-disable-next-line @typescript-eslint/no-explicit-any
            channel.listen(".pricechange", (data: any) => {
                const { buyPrice, sellPrice } = data as PriceChangeData

                if (dataHashmap.current && dataHashmap.current[key]) {
                    const midMarketPrice = ((Number(buyPrice) + Number(sellPrice)) / 2).toFixed(2)
                    if (dataHashmap.current) {
                        dataHashmap.current[key].price = midMarketPrice.toString()
                    }
                }
            })
        }
    }

    function sortData(type: "price" | "24h_percent" | "market_cap", incData?: AssetDetails[]): AssetDetails[] {
        const isAsc = propertyAscending === type
        const copy = [...(incData ?? data)] // incData only used for initial sort after fetching data
        copy.sort((a: AssetDetails, b: AssetDetails) => {
            if (type === "price" || type === "24h_percent") {
                return isAsc
                    ? (Number(a[type]) ?? 0) - (Number(b[type]) ?? 0)
                    : (Number(b[type]) ?? 0) - (Number(a[type]) ?? 0)
            }

            const marketCapA = a.statistics?.market_cap_cad ?? a.statistics?.market_cap_usd ?? 0
            const marketCapB = b.statistics?.market_cap_cad ?? b.statistics?.market_cap_usd ?? 0
            return isAsc ? marketCapA - marketCapB : marketCapB - marketCapA
        })
        return copy
    }

    function sortDisplayData(type: "price" | "24h_percent" | "market_cap"): void {
        const sortedData = sortData(type)
        setDisplayedData(sortedData)
        setPropertyAscending(type === propertyAscending ? null : type)
    }

    // side drawer always starts off with bitcoin
    // maybe will change this later to persist the last selected coin
    function handleSelectBitcoin() {
        const BTC = data.find((coin) => coin.name === "Bitcoin")
        if (BTC) {
            setSelectedCoin(BTC)
        }
    }

    function handleViewMore(symbol: string) {
        navigate(`/dashboard/trade/${symbol}`)
    }

    return (
        <Box display={"flex"} flexDir={"column"}>
            <Box display="flex" flexDir={{ base: "column", md: "row" }} rowGap="1rem" columnGap="1.5rem" mb="1.5rem">
                <Box
                    display="flex"
                    flex={1}
                    flexDirection={{ base: "column", md: "row" }}
                    alignItems="center"
                    justifyContent={"space-between"}
                    rowGap={"1rem"}
                >
                    <Box>
                        <Text textStyle="ManropeHeader">{t("trade.trade")}</Text>
                        <Text>{t("trade.subtitle")}</Text>
                    </Box>
                </Box>
                <Box display="flex" flex={1} alignItems={"center"} justifyContent={"flex-end"} columnGap="0.5rem">
                    <StandardButton
                        flexGrow={1}
                        type="button"
                        leftIcon={<Plus />}
                        variant={ENUM_BUTTON_VARIANTS.BLUE_PRIMARY}
                        onClick={handleSelectBitcoin}
                        alignSelf="flex-end"
                        size="xl"
                    >
                        {t("buy", { ns: "common" })}
                    </StandardButton>
                    <StandardButton
                        flexGrow={1}
                        type="button"
                        leftIcon={<Minus />}
                        variant={ENUM_BUTTON_VARIANTS.BLUE_PRIMARY}
                        onClick={handleSelectBitcoin}
                        alignSelf="flex-end"
                        size="xl"
                    >
                        {t("sell", { ns: "common" })}
                    </StandardButton>
                </Box>
            </Box>
            <Box
                display="flex"
                flexDirection={{ base: "column", md: "row" }}
                alignItems={{ base: "flex-start", md: "center" }}
                justifyContent={"space-between"}
                marginBottom="1.5rem"
                columnGap={"1.5rem"}
                rowGap={"1rem"}
            >
                <Tabs
                    tabIndex={tabIndex}
                    setTabIndex={setTabIndex}
                    tabs={[t("allCoins", { ns: "common" }), t("trade.favourites")]}
                    icons={[Hamburger, Star]}
                    flex={1}
                />
                <Searchbar flex={1} onChange={handleSearchChange} />
            </Box>
            <TableContainer flexGrow={1} display="flex" flexDir={"column"}>
                <Table variant="simple" layout="fixed" w="full" pos="relative">
                    <Thead w="full">
                        <Tr
                            sx={{
                                "& th": {
                                    textTransform: "none",
                                },
                            }}
                            borderBottom="1px solid #636366"
                        >
                            <Th width={{ base: undefined, md: "250px", lg: "275px" }}>Coin Name</Th>
                            <ThSorting
                                width={{ base: "150px", lg: "175px" }}
                                text="Market Price"
                                onClick={() => sortDisplayData("price")}
                            />
                            <ThSorting
                                display={{ base: "none", smmd: "table-cell" }}
                                text="24H Change"
                                onClick={() => sortDisplayData("24h_percent")}
                            />
                            <ThSorting
                                display={{ base: "none", md: "table-cell" }}
                                text="Market Cap"
                                onClick={() => sortDisplayData("market_cap")}
                            />
                            <Th display={{ base: "none", lg: "table-cell" }}></Th>
                        </Tr>
                    </Thead>
                    <Tbody w="full" h="full">
                        {displayedData.map((coin, i) => (
                            <CoinTradingRow
                                key={coin.symbol + i}
                                coin={coin}
                                isFavourite={false} // TODO isFavourite
                                handleViewMore={handleViewMore}
                                isLast={i === displayedData.length - 1}
                            />
                        ))}
                    </Tbody>
                </Table>
                {/* <DashboardFooter country="CA" mb={{ base: "1rem", sm: "1.5rem" }} /> */}
            </TableContainer>
            <Box display="block" w="full">
                {!data.length ? (
                    <Text mt="5rem" textAlign="center">
                        Loading...
                    </Text>
                ) : !displayedData.length ? (
                    <Box textAlign={"center"} mt="5rem">
                        <Box display="inline-block" p="1rem" borderRadius={"0.5rem"} bgColor="grey.10" mb="0.5rem">
                            <Search color={"#48484A"} />
                        </Box>
                        <Text>No results</Text>
                    </Box>
                ) : null}
            </Box>
            <DrawerCoinTrade
                coin={selectedCoin}
                isOpen={!!selectedCoin}
                toggleSideDrawerOpen={() => setSelectedCoin(null)}
            />
        </Box>
    )
}
