export enum MARKET_HISTORY_PERIODS {
    "24h" = "24h",
    "1W" = "1w",
    "1M" = "1m",
    "1Y" = "1y",
}

export type MarketHistoryPoint = {
    buy_price: string
    created_at: string
}

export type MarketHistoryPromisesResponse = {
    period: MARKET_HISTORY_PERIODS
    data: MarketHistoryPoint[]
}
