import MobileDownloads from "@/components/mobileDownloads"
import { Box, Text } from "@chakra-ui/layout"
import StandardButton from "@components/ui/buttons/standard"
import { ENUM_BUTTON_VARIANTS } from "@components/ui/buttons/standard/types"
import { useTranslation } from "react-i18next"

type Props = {
    toggleOff: () => void
}

export default function NotCanadian({ toggleOff }: Props) {
    const { t } = useTranslation(["onboarding", "common"])

    return (
        <Box display="flex" flexDir="column" rowGap="1.5rem">
            <Text textStyle="InterTitle">{t("whereReside.notAvailable")}</Text>
            <Text textStyle="InterSmall">{t("whereReside.thisMoment")}</Text>
            <Text textStyle="InterSmall">
                <Text as="span" fontWeight={900}>
                    {t("whereReside.goodNews")}&nbsp;
                </Text>
                {t("whereReside.ifUSA")}
            </Text>
            <MobileDownloads />
            <StandardButton
                data-testid="back-button"
                type="button"
                variant={ENUM_BUTTON_VARIANTS.DARK_PURPLE_PRIMARY}
                onClick={toggleOff}
            >
                {t("goBack", { ns: "common" })}
            </StandardButton>
        </Box>
    )
}
