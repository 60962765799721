import { assetDetailsApi } from "@redux/assetsDetails/apiSlice"
import authReducer from "@redux/auth/slice"

import { combineReducers } from "@reduxjs/toolkit"

const appReducer = combineReducers({
    [assetDetailsApi.reducerPath]: assetDetailsApi.reducer,
    auth: authReducer,
})

export type RootState = ReturnType<typeof appReducer>

export default appReducer
