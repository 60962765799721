export enum ENUM_SEARCHBAR_VARIANTS {
    PRIMARY = "PRIMARY",
}

type STATEFUL_PROPERTIES = {
    bg: string
    borderColor: string
    color: string
    icon: string
    placeholder?: string
}

type STRUCTURE_SEARCHBAR_VARIANT = {
    normal: STATEFUL_PROPERTIES
    hover: STATEFUL_PROPERTIES
    active: STATEFUL_PROPERTIES
    disabled: STATEFUL_PROPERTIES
}

const SEARCHBAR_VARIANTS: Record<ENUM_SEARCHBAR_VARIANTS, STRUCTURE_SEARCHBAR_VARIANT> = {
    PRIMARY: {
        normal: {
            bg: "white",
            borderColor: "grey.20",
            color: "bluePurple.100",
            icon: "black",
            placeholder: "grey.50",
        },
        hover: {
            bg: "white",
            borderColor: "blue.100",
            color: "white",
            icon: "black",
        },
        disabled: {
            bg: "white",
            borderColor: "grey.20",
            color: "white",
            icon: "black",
        },
        active: {
            bg: "white",
            borderColor: "grey.20",
            color: "blue.100",
            icon: "black",
        },
    },
}

export default SEARCHBAR_VARIANTS
