type Props = {
    size: number
    color: string
    rotation?: number // default is pointing left
}

export default function Arrow({ size, color, rotation }: Props) {
    return (
        <div style={{ rotate: `${rotation ?? 0}deg` }}>
            <svg width={size} height={size} viewBox="0 0 25 25" fill="none" xmlns="http://www.w3.org/2000/svg">
                <path
                    d="M20.5732 11.7481V13.7481H8.57315L14.0732 19.2481L12.6532 20.6681L4.73315 12.7481L12.6532 4.82806L14.0732 6.24806L8.57315 11.7481H20.5732Z"
                    fill={color}
                />
            </svg>
        </div>
    )
}
