import { Button, ButtonProps } from "@chakra-ui/react"
import { useMemo } from "react"
import BUTTON_VARIANTS, { ENUM_BUTTON_VARIANTS } from "./types"

type Props = {
    children: string
    variant: ENUM_BUTTON_VARIANTS
    type: "button" | "submit"
    onClick?: () => void
    size?: "sm" | "md" | "lg" | "xl" | "2xl"
    disabled?: boolean
    isLoading?: boolean
    leftIcon?: React.ReactElement
    icon?: React.ReactElement
} & Omit<ButtonProps, "variant">

export default function StandardButton({
    children,
    onClick,
    type,
    leftIcon,
    textStyle = "InterSubtitle",
    size = "lg",
    variant,
    disabled = false,
    isLoading,
    ...rest
}: Props) {
    const palette = useMemo(() => BUTTON_VARIANTS[variant], [variant])

    const { normal, hover, disabled: disabledColor, active } = palette

    return (
        <Button
            fontFamily="Inter"
            fontSize="16px"
            fontStyle="normal"
            fontWeight="600"
            onClick={onClick}
            bg={normal.bg}
            disabled={disabled || isLoading}
            _disabled={disabledColor}
            _hover={!disabled ? hover : {}}
            _active={active}
            borderWidth="2px"
            borderColor={normal.borderColor}
            size={size}
            type={type}
            textStyle={textStyle}
            color={normal.color}
            isLoading={isLoading}
            leftIcon={leftIcon}
            {...rest}
        >
            {children}
        </Button>
    )
}
