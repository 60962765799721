import common from "./en/common.json"
import login from "./en/login"
import onboarding from "./en/onboarding"
import legal from "./en/legal"
import app from "./en/app"

const resources = {
    en: {
        app,
        common,
        legal,
        login,
        onboarding,
    },
} as const

export default resources
