export default function StarFilled({ size = 24, color = "white" }: { size?: number; color?: string }) {
    return (
        <svg width={size} height={size} viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path
                d="M10 14.3917L15.15 17.5L13.7834 11.6417L18.3334 7.7L12.3417 7.18333L10 1.66666L7.65835 7.18333L1.66669 7.7L6.20835 11.6417L4.85002 17.5L10 14.3917Z"
                fill={color}
            />
        </svg>
    )
}
