import {
    Box,
    NumberInput as ChakraNumberInput,
    FormControl,
    FormControlProps,
    FormLabel,
    InputGroup,
    NumberInputField,
    Text,
    useTheme,
} from "@chakra-ui/react"
import { FIELD_SIZE } from "@components/ui/types"
import React from "react"
import { useController, UseControllerProps } from "react-hook-form"
import useColorFormatConverter from "theme/useColorFormatConverter"
import ErrorMessage from "../errorMessage"
import colors from "../textInput/theme.json"

interface _Props {
    baseColor?: string
    placeholder?: string
    size?: "sm" | "md" | "lg"
    label?: string
    staticText?: string
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    [rest: string]: any
}

// eslint-disable-next-line @typescript-eslint/no-explicit-any
type Props = _Props & UseControllerProps<any> & FormControlProps

export default function NumberInput(props: Props) {
    const { name, rules, control, placeholder, disabled, label, size, baseColor = "white", staticText, ...rest } = props

    const theme = useTheme()

    const {
        field,
        fieldState: { invalid, error },
        // eslint-disable-next-line @typescript-eslint/no-explicit-any
    } = useController<any>({
        name,
        control,
        rules,
    })

    const colorConverter = useColorFormatConverter()

    const palette = React.useMemo(() => {
        return colors[baseColor as keyof typeof colors]
    }, [])

    const getBorderColor = React.useCallback(
        (isFocused: boolean) => {
            if (error) {
                return palette.error
            }
            if (isFocused) {
                return "blue.100"
            }
            return "transparent"
        },
        [error, palette]
    )

    const innerBorderColor = React.useMemo(() => (error ? palette.error : theme.colors.blue[100]), [error, theme])

    return (
        <FormControl isInvalid={invalid} {...rest}>
            {label && (
                <FormLabel fontSize={14} lineHeight={"100%"} htmlFor={name}>
                    {label}&nbsp;
                    {/*  eslint-disable-next-line no-prototype-builtins */}
                    {rules?.hasOwnProperty("required") && (
                        <Text as="span" color="blue.100">
                            *
                        </Text>
                    )}
                </FormLabel>
            )}
            <InputGroup
                _focusWithin={{
                    borderColor: getBorderColor(true),
                    ".chakra-input__left-addon": {
                        borderColor: `${innerBorderColor} !important`,
                    },
                    ".chakra-input": {
                        borderTopColor: `${innerBorderColor} !important`,
                        borderBottomColor: `${innerBorderColor} !important`,
                        borderLeftColor: `${innerBorderColor} !important`,
                        borderRightColor: `${innerBorderColor} !important`,
                    },
                }}
                borderColor={getBorderColor(false)}
                borderWidth="1px"
                borderRadius="8px"
                h={`${FIELD_SIZE[size ?? "lg"]}px`}
            >
                {staticText && (
                    <Box
                        borderTopWidth="1px"
                        borderBottomWidth="1px"
                        borderRightWidth={0}
                        borderLeftWidth={"1px"}
                        borderTopRightRadius={0}
                        borderBottomRightRadius={0}
                        borderTopLeftRadius={"8px"}
                        borderBottomLeftRadius={"8px"}
                        height={`${FIELD_SIZE[size ?? "lg"] - 2}px`}
                        display="flex"
                        alignItems={"center"}
                        justifyContent={"center"}
                        borderColor={!error && baseColor === "white" ? palette?.border : "transparent"}
                        _hover={{
                            borderColor: !error && baseColor === "white" ? palette?.border : "transparent",
                        }}
                    >
                        <Text ml="1rem">{staticText}</Text>
                    </Box>
                )}
                <ChakraNumberInput
                    {...field}
                    h="full"
                    display="flex"
                    alignItems={"center"}
                    data-testid={`input-${name}`}
                    value={field.value ?? ""} // stop uncontrolled input warning
                    _placeholder={{ opacity: 0.4, color: palette?.placeholder }}
                    fontFamily="Inter"
                    fontSize="16px"
                    fontStyle="normal"
                    fontWeight="400"
                    lineHeight="150%"
                    bgColor={error ? palette.errorBg : baseColor}
                    color={palette?.text}
                    borderColor={
                        error
                            ? colorConverter(palette.error) + " " + "!important"
                            : !error && baseColor === "white"
                              ? palette?.border
                              : "transparent"
                    }
                    _hover={{
                        borderColor: !error && baseColor === "white" ? palette?.border : "transparent",
                    }}
                    _disabled={{
                        borderColor: palette?.border,
                    }}
                    borderTopWidth="1px"
                    borderBottomWidth="1px"
                    borderLeftWidth={"1px"}
                    borderRightWidth={"1px"}
                    focusBorderColor="transparent"
                    errorBorderColor="transparent"
                    borderTopLeftRadius={"8px"}
                    borderBottomLeftRadius={"8px"}
                    borderBottomRightRadius={"8px"}
                    borderTopRightRadius={"8px"}
                    _autofill={{
                        WebkitTextFillColor: colorConverter(palette.text),
                        WebkitBoxShadow: `inset 0 0 20px 20px ${
                            baseColor === "white" || baseColor === "black" ? baseColor : colorConverter(baseColor)
                        }`,
                    }}
                >
                    <NumberInputField disabled={disabled} placeholder={placeholder} border="none" />
                </ChakraNumberInput>
            </InputGroup>
            {!!error && <ErrorMessage color={palette?.text}>{error?.message}</ErrorMessage>}
        </FormControl>
    )
}
