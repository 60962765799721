import AddressForm, { AddressFormData } from "@/components/forms/address"
import StandardButton from "@/components/ui/buttons/standard"
import { ENUM_BUTTON_VARIANTS } from "@/components/ui/buttons/standard/types"
import AmplitudeClient from "@/sdks/amplitude"
import { formatDefaultLocationValues } from "@/util/optionsFormatted"
import { formatPostalCode } from "@/util/stringFormatting"
import { Box, Text } from "@chakra-ui/react"
import DetailsTypeCard from "@components/ui/breadcrumbs/detailsType"
import React from "react"
import { useForm } from "react-hook-form"
import { useTranslation } from "react-i18next"
import { SubScreenProps } from ".."
import { AccountType, REGISTRATION_STEP, REG_SCREENS } from "../types"

export default function WhereBusinessOffice({ submitSubForm, isLoading, userObj, setUserObj }: SubScreenProps) {
    const { handleSubmit, control, watch, setValue } = useForm<AddressFormData>(formatDefaultValues())
    const { t } = useTranslation(["onboarding", "common"])

    const [initialLoadCount, setInitialLoadCount] = React.useState(0)

    function formatDefaultValues() {
        if (userObj?.business.address && userObj?.business?.address?.country && userObj?.business?.address?.region) {
            const address = userObj.business.address
            const {
                street_address: streetAddress,
                building_address: buildingAddress,
                city,
                postal_code: postalCode,
                country,
                region,
            } = address
            const countryAndRegion = formatDefaultLocationValues(country || "", region || "")
            if (countryAndRegion) {
                const addressStr = streetAddress?.split(" ")
                const streetNumber = addressStr?.length ? addressStr.shift() : undefined
                // skips the first element cause it was shifted above
                const streetName = addressStr?.length ? addressStr.join(" ") : undefined
                return {
                    defaultValues: {
                        country: countryAndRegion?.country,
                        region: countryAndRegion?.region,
                        street_number: streetNumber || undefined,
                        street_name: streetName || undefined,
                        building_address: buildingAddress || undefined,
                        city: city || undefined,
                        postal_code: postalCode || undefined,
                    },
                }
            }
        }
        return undefined
    }

    const isCanada = React.useMemo(() => watch("country")?.value === "CA", [watch("country")?.value])

    // Forces region to clear on country change
    React.useEffect(() => {
        // ... this is dumb but here we are
        // It waits for an intial load and then possibly again if the defaultValue gets set
        if ((userObj?.business?.address?.country && initialLoadCount > 1) || !userObj?.business?.address?.country) {
            // @ts-expect-error Set value as null
            setValue("region", null)
        } else {
            setInitialLoadCount((prev) => prev + 1)
        }
    }, [isCanada])

    const onSubmit = async (values: AddressFormData) => {
        const {
            street_number: streetNumber,
            street_name: streetName,
            building_address: buildingAddress,
            city,
            postal_code: postalCode,
            country,
            region,
        } = values

        const payload = {
            address: {
                street_address: streetNumber.trim().replace(/\s/g, "") + " " + streetName,
                building_address: buildingAddress?.trim(),
                city: city.trim(),
                region: region.value,
                country: country.value,
                postal_code: formatPostalCode(country.value, postalCode),
            },
        }

        await submitSubForm("/api/v2/account/business", payload, () => {
            setUserObj((prev) => (prev ? { ...prev, business: { ...prev.business, ...payload } } : prev))
            AmplitudeClient.logRegistrationEvent(REG_SCREENS.WhereBusinessOffice, REGISTRATION_STEP.BUSINESS_ADDRESS)
        })
    }

    return (
        <Box w="full">
            {userObj?.type === AccountType.BUSINESS && <DetailsTypeCard isPersonal={false} />}
            <form onSubmit={handleSubmit(onSubmit)}>
                <Box display="flex" flexDir="column" rowGap="1.5rem">
                    <Text textStyle="InterTitle">{t("whereLive.business.whereDoYou")}</Text>
                    <AddressForm control={control} isCanada={isCanada} areLocationsDisabled={false} />
                    <StandardButton
                        data-testid="continue-button"
                        type="submit"
                        variant={ENUM_BUTTON_VARIANTS.DARK_PURPLE_PRIMARY}
                        mt="1.5rem"
                        isLoading={isLoading}
                    >
                        {t("continue", { ns: "common" })}
                    </StandardButton>
                </Box>
            </form>
        </Box>
    )
}
