import React from "react"
import { Box, BoxProps } from "@chakra-ui/react"
import { useTranslation } from "react-i18next"

type Props = {
    isPersonal: boolean
} & BoxProps

export default function DetailsTypeCard({ isPersonal, ...rest }: Props) {
    const { t } = useTranslation("onboarding", { keyPrefix: "extra" })

    return (
        <Box
            display="inline-block"
            py="0.5rem"
            px="1rem"
            borderRadius="0.25rem"
            color="darkBlue.80"
            bgColor="darkBlue.10"
            mb="0.75rem"
            {...rest}
        >
            {isPersonal ? t("personalDetails") : t("businessDetails")}
        </Box>
    )
}
