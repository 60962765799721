import { Tabs as ChakraTabs, TabList, TabPanels, Tab as ChakraTab, TabPanel, Box, Text } from "@chakra-ui/react"
import TAB_VARIANTS, { ENUM_TAB_VARIANTS } from "./types"
import { useMemo } from "react"
import useColorFormatConverter from "theme/useColorFormatConverter"

type Props = {
    tabIndex: number
    setTabIndex: (_: number) => void
    tabs: string[]
    tabPanels?: React.ReactElement[] // element index mirrors 'tabs'
    variant?: ENUM_TAB_VARIANTS
    name?: string
    icons?: (({ size, color }: { size?: number; color?: string }) => React.ReactNode)[]
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    [rest: string]: any
}

export default function Tabs({
    tabIndex,
    setTabIndex,
    tabs,
    tabPanels,
    name,
    variant = ENUM_TAB_VARIANTS.PRIMARY,
    icons,
    ...rest
}: Props) {
    const colorConverter = useColorFormatConverter()

    const palette = useMemo(() => {
        return TAB_VARIANTS[variant]
    }, [variant])

    return (
        <ChakraTabs
            data-testid={`tabs-${name}`}
            borderBottom="none"
            border="none"
            index={tabIndex}
            onChange={(index) => setTabIndex(index)}
            {...rest}
        >
            <TabList borderBottom="none" border="none" columnGap={`${palette.gap}rem`}>
                {tabs.map((tab, index) => {
                    const isActive = tabIndex === index
                    return (
                        <ChakraTab
                            borderRadius={`${palette.borderRadius}px`}
                            borderWidth={`${palette.borderWidth}px`}
                            borderColor={isActive ? palette.active.borderColor : palette.normal.borderColor}
                            borderStyle="solid"
                            color={palette.normal.borderColor}
                            _hover={{
                                bg: palette.hover.bg,
                                color: palette.hover.color,
                                borderColor: palette.hover.borderColor,
                            }}
                            key={index}
                            bgColor={isActive ? palette.active.bg : palette.normal.bg}
                        >
                            <Box color={palette.normal.color} display="flex" columnGap="0.5rem" alignItems="center">
                                {icons &&
                                    icons[index]({
                                        size: 20,
                                        color: colorConverter(isActive ? palette.active.color : palette.normal.color),
                                    })}
                                <Text
                                    whiteSpace={"nowrap"}
                                    fontWeight={isActive ? 700 : 500}
                                    fontSize={14}
                                    color={isActive ? palette.active.color : palette.normal.color}
                                >
                                    {tab}
                                </Text>
                            </Box>
                        </ChakraTab>
                    )
                })}
            </TabList>
            {/* Tabs can optionally control the shown view outside of this component manually */}
            {tabPanels?.length && (
                <TabPanels p="2rem">
                    {tabPanels?.map((tabPanel, index) => <TabPanel key={index}>{tabPanel}</TabPanel>)}
                </TabPanels>
            )}
        </ChakraTabs>
    )
}
