export default function HelpCircle({ size = 24, color = "white" }: { size?: number; color?: string }) {
    return (
        <svg width={size} height={size} viewBox="0 0 18 18" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path
                d="M8.25 13.5061H9.75V12.0061H8.25V13.5061ZM9 1.5061C8.01509 1.5061 7.03982 1.7001 6.12987 2.07701C5.21993 2.45392 4.39314 3.00636 3.6967 3.7028C2.29018 5.10933 1.5 7.01698 1.5 9.0061C1.5 10.9952 2.29018 12.9029 3.6967 14.3094C4.39314 15.0058 5.21993 15.5583 6.12987 15.9352C7.03982 16.3121 8.01509 16.5061 9 16.5061C10.9891 16.5061 12.8968 15.7159 14.3033 14.3094C15.7098 12.9029 16.5 10.9952 16.5 9.0061C16.5 8.02119 16.306 7.04592 15.9291 6.13598C15.5522 5.22604 14.9997 4.39924 14.3033 3.7028C13.6069 3.00636 12.7801 2.45392 11.8701 2.07701C10.9602 1.7001 9.98491 1.5061 9 1.5061ZM9 15.0061C5.6925 15.0061 3 12.3136 3 9.0061C3 5.6986 5.6925 3.0061 9 3.0061C12.3075 3.0061 15 5.6986 15 9.0061C15 12.3136 12.3075 15.0061 9 15.0061ZM9 4.5061C8.20435 4.5061 7.44129 4.82217 6.87868 5.38478C6.31607 5.94739 6 6.71045 6 7.5061H7.5C7.5 7.10828 7.65804 6.72675 7.93934 6.44544C8.22064 6.16414 8.60218 6.0061 9 6.0061C9.39782 6.0061 9.77936 6.16414 10.0607 6.44544C10.342 6.72675 10.5 7.10828 10.5 7.5061C10.5 9.0061 8.25 8.8186 8.25 11.2561H9.75C9.75 9.5686 12 9.3811 12 7.5061C12 6.71045 11.6839 5.94739 11.1213 5.38478C10.5587 4.82217 9.79565 4.5061 9 4.5061Z"
                fill={color}
            />
        </svg>
    )
}
