import { Box, Image, Text } from "@chakra-ui/react"
import { useTranslation } from "react-i18next"

export default function MobileDownloads() {
    const { t } = useTranslation(["onboarding", "common"])

    return (
        <Box
            bgColor="darkPurple.10"
            px="1rem"
            borderRadius="8px"
            display="flex"
            flexDir={{ base: "column", sm: "row" }}
            pb={{ base: "1rem", sm: "0" }}
        >
            <Image src="images/mobile/two-phones.png" alt="two-phones" height="262px" mb={{ base: "1rem", sm: "0" }} />
            <Box pl="1rem" display="flex" flexDir="column" flexGrow={1} justifyContent="center" alignItems="center">
                <Text
                    alignSelf={{ base: "center", sm: "flex-start" }}
                    textAlign={{ base: "center", sm: "start" }}
                    textStyle="ManropeSubtitleSmall"
                    mb="1rem"
                >
                    {t("whereReside.upToDate")}
                </Text>
                <Text alignSelf={{ base: "center", sm: "flex-start" }} textStyle="ManropeSubtitleSmall" mb="1rem">
                    {t("whereReside.download")}
                </Text>
                <Image
                    cursor="pointer"
                    onClick={() =>
                        window.open("https://play.google.com/store/apps/details?id=com.netcoins.app&hl=en&gl=US&pli=1")
                    }
                    src="images/mobile/get_on_google_play.png"
                    alt="play_store"
                    w="194px"
                    mb="0.25rem"
                />
                <Image
                    cursor="pointer"
                    src="images/mobile/get_on_app_store.png"
                    onClick={() => window.open("https://apps.apple.com/ca/app/netcoins-buy-crypto-easily/id1571344881")}
                    alt="app_store"
                    w="194px"
                />
            </Box>
        </Box>
    )
}
