export default function Plus({ size = 24, color = "white" }: { size?: number; color?: string }) {
    return (
        <svg width={size} height={size} viewBox="0 0 15 15" fill={color} xmlns="http://www.w3.org/2000/svg">
            <path
                d="M11.7094 8.43632H8.20939V11.9363H7.04272V8.43632H3.54272V7.26965H7.04272V3.76965H8.20939V7.26965H11.7094V8.43632Z"
                fill={color}
            />
        </svg>
    )
}
