type Props = {
    width: number
    color: string
}

export default function Eye({ width, color }: Props) {
    return (
        <svg
            width={width}
            height={(20 / 21) * width}
            viewBox="0 0 20 21"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
        >
            <path
                d="M9.99992 7.69739C10.663 7.69739 11.2988 7.96078 11.7677 8.42962C12.2365 8.89846 12.4999 9.53435 12.4999 10.1974C12.4999 10.8604 12.2365 11.4963 11.7677 11.9652C11.2988 12.434 10.663 12.6974 9.99992 12.6974C9.33688 12.6974 8.70099 12.434 8.23215 11.9652C7.76331 11.4963 7.49992 10.8604 7.49992 10.1974C7.49992 9.53435 7.76331 8.89846 8.23215 8.42962C8.70099 7.96078 9.33688 7.69739 9.99992 7.69739V7.69739ZM9.99992 3.94739C14.1666 3.94739 17.7249 6.53905 19.1666 10.1974C17.7249 13.8557 14.1666 16.4474 9.99992 16.4474C5.83325 16.4474 2.27492 13.8557 0.833252 10.1974C2.27492 6.53905 5.83325 3.94739 9.99992 3.94739ZM2.64992 10.1974C4.02492 12.9974 6.86658 14.7807 9.99992 14.7807C13.1333 14.7807 15.9749 12.9974 17.3499 10.1974C15.9749 7.39739 13.1333 5.61405 9.99992 5.61405C6.86658 5.61405 4.02492 7.39739 2.64992 10.1974Z"
                fill={color}
            />
        </svg>
    )
}
