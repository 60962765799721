import React, { PropsWithChildren, useEffect } from "react"
import { useAppDispatch, useAppSelector } from "@/store/hooks"
import { initializeUserManagerEvents, resolveUserOrLogin } from "@redux/auth/slice"

const AuthProvider: React.FC<PropsWithChildren> = ({ children }) => {
    const dispatch = useAppDispatch()

    const user = useAppSelector((state) => state.auth.user)
    const loading = useAppSelector((state) => state.auth.loading)

    useEffect(() => {
        dispatch(resolveUserOrLogin())
        initializeUserManagerEvents(dispatch)
    }, [])

    if (loading) {
        // For future purposes to add a loading screen while authentication resolves
        return <></>
    }

    return <>{user ? children : <></>}</>
}

export default AuthProvider
