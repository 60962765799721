export default function Chevron({
    size = 24,
    color = "white",
    rotation,
}: {
    size?: number
    color?: string
    rotation?: number
}) {
    return (
        <div style={{ rotate: `${rotation ?? 0}deg` }}>
            <svg width={size} height={size} viewBox="0 0 16 16" fill={color} xmlns="http://www.w3.org/2000/svg">
                <path d="M4 12.58L8.58 8L4 3.41L5.41 2L11.41 8L5.41 14L4 12.58Z" fill={color} />
            </svg>
        </div>
    )
}
