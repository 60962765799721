import { checkboxAnatomy } from "@chakra-ui/anatomy"
import { createMultiStyleConfigHelpers } from "@chakra-ui/react"

const { definePartsStyle, defineMultiStyleConfig } = createMultiStyleConfigHelpers(checkboxAnatomy.keys)

const baseStyle = definePartsStyle({
    container: {
        _hover: {
            bg: "blue.100",
            color: "white",
        },
        _active: {
            bg: "blue.100",
            color: "white",
        },
    },
    icon: {
        _groupHover: {
            color: "blue.70",
        },
        color: "blue.100",
    },
    control: {
        marginLeft: 2,
        _groupHover: {
            bg: "blue.100",
            borderColor: "blue.70",
        },
        _checked: {
            color: "blue.100",
            borderColor: "blue.100",
            bg: "white",
        },
        _indeterminate: {
            borderColor: "gray.60",
            bg: "white",
        },
    },
})

export const checkboxTheme = defineMultiStyleConfig({ baseStyle })
