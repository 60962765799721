import React from "react"
import { Box, Text } from "@chakra-ui/react"
import { useTranslation } from "react-i18next"
import StandardButton from "@/components/ui/buttons/standard"
import { ENUM_BUTTON_VARIANTS } from "@/components/ui/buttons/standard/types"

export default function NotNA() {
    const { t } = useTranslation(["onboarding", "common"])

    return (
        <Box w="full">
            <Text textStyle="InterTitle" mb="0.75rem">
                {t("extra.notNATitle")}
            </Text>
            <Text textStyle="InterSmall" mb="3rem">
                {t("extra.notNADesc")}.
            </Text>
            <StandardButton
                data-testid="logout-button"
                onClick={() => window.open("/logout", "_self")}
                type="button"
                variant={ENUM_BUTTON_VARIANTS.DARK_PURPLE_PRIMARY}
                w="full"
            >
                {t("logout", { ns: "common" })}
            </StandardButton>
        </Box>
    )
}
