import { Box, Link, Text } from "@chakra-ui/react"
import ArrowsVertSlanted from "assets/svgs/arrows/arrows-vert-slanted"
import ChartUp from "assets/svgs/chart-up"
import CreditCard from "assets/svgs/credit-card"
import FourBlock from "assets/svgs/four-block"
import { NavLink, useLocation } from "react-router-dom"
import { ROUTES } from "routing/routes"

export type NavButton = {
    label: string
    path: string
    icon: (color: string, size?: number) => JSX.Element
}

export const SIDE_BAR_ROUTES: NavButton[] = [
    { label: "Dashboard", path: ROUTES.DASHBOARD, icon: (color) => <FourBlock color={color} /> },
    { label: "Trade", path: ROUTES.DASHBOARD_TRADE, icon: (color) => <ChartUp color={color} /> },
    { label: "Transfer", path: ROUTES.DASHBOARD_TRANSFER, icon: (color) => <ArrowsVertSlanted color={color} /> },
    { label: "Netcoins Pay", path: ROUTES.DASHBOARD_NETCOINS_PAY, icon: (color) => <CreditCard color={color} /> },
]

export default function Sidebar() {
    const location = useLocation()

    return (
        <Box
            display={{ base: "none", sm: "inline-block" }}
            h="full"
            p="1.5rem 0.75rem"
            width={{ base: "fit-content", lg: "250px" }}
            borderRight="1px solid #AEAEB280"
        >
            {SIDE_BAR_ROUTES.map((route: NavButton) => (
                <Link
                    as={NavLink}
                    end
                    key={route.path}
                    _hover={{
                        bgColor: {
                            base: "bluePurple.10",
                            lg: location.pathname === `${route.path}` ? "bluePurple.100" : "blue.10",
                        },
                    }}
                    to={route.path}
                    display="flex"
                    alignItems="center"
                    columnGap="1.5rem"
                    p={{ base: "0.75rem", lg: "0.5rem 0.75rem" }}
                    borderRadius="4px"
                    mb="1rem"
                    _activeLink={{
                        bgColor: "bluePurple.100",
                    }}
                >
                    {route.icon(location.pathname === `${route.path}` ? "white" : "#07072E")}
                    <Text
                        display={{ base: "none", lg: "inline-block" }}
                        color={location.pathname === `${route.path}` ? "white" : "bluePurple.100"}
                    >
                        {route.label}
                    </Text>
                </Link>
            ))}
        </Box>
    )
}
