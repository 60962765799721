export const TextStyles = {
    ManropeBanner: {
        fontFamily: "Manrope",
        fontSize: "64px",
        fontStyle: "normal",
        fontWeight: 400,
        lineHeight: "74px",
        letterSpacing: "0.5px",
    },
    ManropeHeader: {
        fontFamily: "Manrope",
        fontSize: "40px",
        fontStyle: "normal",
        fontWeight: 500,
        lineHeight: "normal",
        letterSpacing: "0.5px",
    },
    ManropeSubtitleSmall: {
        fontFamily: "Manrope",
        fontSize: "12px",
        fontStyle: "normal",
        fontWeight: 600,
        lineHeight: "150%" /* 18px */,
        letterSpacing: "0.25px",
    },
    ManropeBody: {
        fontFamily: "Manrope",
        fontSize: "16px",
        fontStyle: "normal",
        fontWeight: 600,
        lineHeight: "150%",
        letterSpacing: "0.08px",
    },
    ManropeBold: {
        fontFamily: "Manrope",
        fontSize: "16px",
        lineHeight: "24px",
        fontWeight: 700,
        fontStyle: "bold",
    },
    ManropeSemiBold: {
        fontFamily: "Manrope",
        fontSize: "14px",
        fontStyle: "normal",
        fontWeight: 600,
        lineHeight: "21px",
        letterSpacing: "0.15px",
    },
    ManropeSemiBoldBig: {
        fontFamily: "Manrope",
        fontSize: "14px",
        fontStyle: "normal",
        fontWeight: 600,
        lineHeight: "24px",
        letterSpacing: "0.15px",
    },
    ManropeMedium: {
        fontFamily: "Manrope",
        fontSize: "14px",
        fontStyle: "normal",
        fontWeight: 500,
        lineHeight: "21px",
        letterSpacing: "0.15px",
    },
    ManropeTiny: {
        fontFamily: "Manrope",
        fontSize: "12px",
        fontStyle: "normal",
        fontWeight: 500,
        lineHeight: "150%",
        letterSpacing: "0.25px",
    },
    InterTitle: {
        fontFamily: "Inter",
        fontSize: "20px",
        fontStyle: "normal",
        fontWeight: 400,
        lineHeight: "150%",
    },
    InterSubtitle: {
        fontFamily: "Inter",
        fontSize: "16px",
        fontStyle: "normal",
        fontWeight: 600,
        lineHeight: "150%",
    },
    InterSubtitleSmall: {
        fontFamily: "Inter",
        fontSize: "14px",
        fontStyle: "normal",
        fontWeight: 600,
        lineHeight: "150%",
    },
    InterRegular: {
        fontFamily: "Inter",
        fontSize: "16px",
        fontStyle: "normal",
        fontWeight: 400,
        lineHeight: "150%",
    },
    InterSmall: {
        fontFamily: "Inter",
        fontSize: "14px",
        fontStyle: "normal",
        fontWeight: 400,
        lineHeight: "150%",
    },
    InterTiny: {
        fontFamily: "Inter",
        fontSize: "12px",
        fontStyle: "normal",
        fontWeight: 400,
        lineHeight: "150%",
    },
    InterNano: {
        fontFamily: "Inter",
        fontSize: "10px",
        fontStyle: "normal",
        fontWeight: 400,
        lineHeight: "150%",
    },
}
