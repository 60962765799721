import { Checkbox } from "@chakra-ui/react"
import { useController, UseControllerProps } from "react-hook-form"

type _Props = {
    text?: string
    defaultChecked?: boolean
    size?: "sm" | "md" | "lg"
}

// eslint-disable-next-line @typescript-eslint/no-explicit-any
type Props = _Props & UseControllerProps<any>

export default function CheckboxComponent({ text, defaultChecked, size, name, control, rules, disabled }: Props) {
    const {
        field,
        fieldState: { invalid },
        // eslint-disable-next-line @typescript-eslint/no-explicit-any
    } = useController<any>({
        name,
        control,
        rules,
    })

    return (
        <Checkbox
            defaultChecked={defaultChecked ?? false}
            disabled={disabled}
            size={size ?? "lg"}
            isInvalid={invalid}
            {...field}
            colorScheme="white"
            iconColor="blue.100"
            borderColor="grey.60"
            _checked={{
                borderColor: "blue.100",
            }}
            _active={{
                borderColor: "blue.70",
                "* svg": {
                    color: "blue.70",
                },
            }}
        >
            {text}
        </Checkbox>
    )
}
