type Props = {
    width: number
    color: string
}

export default function EyeOff({ width, color }: Props) {
    return (
        <svg
            width={width}
            height={(21 / 20) * width}
            viewBox="0 0 21 20"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
        >
            <path
                d="M2.16671 4.39171L3.23337 3.33337L17.1667 17.2667L16.1084 18.3334L13.5417 15.7667C12.5834 16.0834 11.5667 16.25 10.5 16.25C6.33337 16.25 2.77504 13.6584 1.33337 10C1.90837 8.53337 2.82504 7.24171 3.99171 6.21671L2.16671 4.39171ZM10.5 7.50004C11.1631 7.50004 11.799 7.76343 12.2678 8.23227C12.7366 8.70111 13 9.337 13 10C13 10.2917 12.95 10.575 12.8584 10.8334L9.66671 7.64171C9.92504 7.55004 10.2084 7.50004 10.5 7.50004ZM10.5 3.75004C14.6667 3.75004 18.225 6.34171 19.6667 10C18.9834 11.7334 17.825 13.2334 16.3334 14.325L15.15 13.1334C16.2834 12.35 17.2167 11.2834 17.85 10C16.475 7.20004 13.6334 5.41671 10.5 5.41671C9.59171 5.41671 8.70004 5.56671 7.86671 5.83337L6.58337 4.55837C7.78337 4.04171 9.10837 3.75004 10.5 3.75004ZM3.15004 10C4.52504 12.8 7.36671 14.5834 10.5 14.5834C11.075 14.5834 11.6417 14.525 12.1667 14.4084L10.2667 12.5C9.07504 12.375 8.12504 11.425 8.00004 10.2334L5.16671 7.39171C4.34171 8.10004 3.65004 8.98337 3.15004 10Z"
                fill={color}
            />
        </svg>
    )
}
