type Props = {
    width: number
    color: string
}

export default function Email({ width, color }: Props) {
    return (
        <svg
            width={width}
            height={(20 / 21) * width}
            viewBox="0 0 20 21"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
        >
            <path
                d="M18.3332 5.19743C18.3332 4.28076 17.5832 3.53076 16.6665 3.53076H3.33317C2.4165 3.53076 1.6665 4.28076 1.6665 5.19743V15.1974C1.6665 16.1141 2.4165 16.8641 3.33317 16.8641H16.6665C17.5832 16.8641 18.3332 16.1141 18.3332 15.1974V5.19743ZM16.6665 5.19743L9.99984 9.36409L3.33317 5.19743H16.6665ZM16.6665 15.1974H3.33317V6.86409L9.99984 11.0308L16.6665 6.86409V15.1974Z"
                fill={color}
            />
        </svg>
    )
}
