export const Colors = {
    alert: {
        error: "#C62828",
        warning: "#FFC255",
        success: "#2CA900",
        info: "blue",
    },
    grey: {
        placeholder: "#AEAEB2",
        secondary: "#5E6572",
        5: "#F4F4F4",
        10: "#F2F2F7",
        20: "#E5E5EA",
        30: "#D1D1D6",
        40: "#C7C7CC",
        50: "#AEAEB2",
        60: "#8E8E93",
        70: "#636366",
        80: "#48484A",
        90: "#3A3A3C",
        100: "#1C1C1E",
        light: {
            10: "#E8E8E8",
            50: "#8D8D8E",
            60: "#777778",
            70: "#606061",
        },
    },
    darkBlue: {
        10: "#E6E8EF",
        70: "#4D6092",
        80: "#334A83",
        100: "#001C64",
        link: "#B5B5C0",
    },
    blue: {
        10: "#ECF0FE",
        20: "#D8E0FD",
        50: "#9EB2FA",
        70: "#7793F9",
        pale: "#D8E0FD",
        80: "#6484F8",
        100: "#3D65F6",
    },
    bluePurple: {
        10: "#E6E6EA",
        20: "#CDCDD5",
        30: "#B5B5C0",
        50: "#838396",
        60: "#6A6A82",
        80: "#393958",
        90: "#202043",
        100: "#07072E",
    },
    darkPurple: {
        10: "#E6E6EA",
        100: "#07072E",
        secondary: "#202043",
    },
    purple: {
        primary: "#312E80",
    },
    green: {
        90: "#1B881F",
    },
    red: {
        40: "#E8A9A9",
        70: "#9B2C2C",
        100: "#C62828",
        200: "#352823",
        "70.pale": "#d7aaaa",
    },
    yellow: {
        100: "#FFC255",
    },
}
