import React from "react"
import { Box, useToast } from "@chakra-ui/react"
import RadioGroup from "components/ui/radio"
import { useTranslation } from "react-i18next"
import { useForm } from "react-hook-form"
import StandardButton from "components/ui/buttons/standard"
import { ENUM_BUTTON_VARIANTS } from "components/ui/buttons/standard/types"
import { REG_SCREENS, AccountType as AccountTypeEnum, REGISTRATION_STEP } from "@screens/onboarding/types"
import AmplitudeClient from "sdks/amplitude"
import useFetchWrapper, { Method } from "hooks/useFetchWrapper"
import { AccountRes } from "@screens/landing/types"
import { SubScreenProps } from "screens/onboarding"
import { Option } from "@components/ui/types"

type FormData = {
    type: Option<AccountTypeEnum>
}

type Props = {
    setIsLoading: React.Dispatch<React.SetStateAction<boolean>>
    setPhases: React.Dispatch<React.SetStateAction<REG_SCREENS[]>>
    phases: REG_SCREENS[]
    setCurrentPhase: React.Dispatch<React.SetStateAction<{ name?: REG_SCREENS; num?: number }>>
} & SubScreenProps

export default function AccountType({ setIsLoading, isLoading, setUserObj, setPhases, setCurrentPhase }: Props) {
    const { t } = useTranslation(["onboarding", "common"])
    const { handleSubmit, control } = useForm<FormData>({
        defaultValues: {
            type: {
                value: AccountTypeEnum.INDIVIDUAL,
                label: `${t("accountType.personal")}`,
                desc: `${t("accountType.personalDesc")}`,
            },
        },
    })
    const { fetchWrapper } = useFetchWrapper()
    const toast = useToast()

    function toastServerError() {
        toast({
            title: t("error.server", { ns: "common" }),
            description: t("error.serverDesc", { ns: "common" }),
            status: "error",
            duration: 10000,
        })
    }

    async function onSubmitForm(values: FormData) {
        const { type } = values
        setIsLoading(true)
        fetchWrapper("/api/v2/account", Method.POST, { type: type.value })
            .then(async (data: { data: AccountRes }) => {
                const _data = data.data as AccountRes
                const nextScreen =
                    data.data.type === AccountTypeEnum.INDIVIDUAL
                        ? REG_SCREENS.WhereReside
                        : REG_SCREENS.WhereIncorporated
                setPhases((prev) => [...prev, nextScreen])
                setUserObj(_data)
                setTimeout(() => {
                    setCurrentPhase({ name: nextScreen, num: 1 })
                }, 0)
                AmplitudeClient.logEvent(REG_SCREENS.AccountType)
            })
            .catch((e) => {
                toastServerError()
            })
            .finally(() => setIsLoading(false))
    }

    return (
        <Box w="full">
            <form onSubmit={handleSubmit(onSubmitForm)}>
                <RadioGroup
                    name="type"
                    isHorizontal={false}
                    control={control}
                    options={[
                        {
                            value: AccountTypeEnum.INDIVIDUAL,
                            label: `${t("accountType.personal")}`,
                            desc: `${t("accountType.personalDesc")}`,
                        },
                        {
                            value: AccountTypeEnum.BUSINESS,
                            label: `${t("accountType.business")}`,
                            desc: `${t("accountType.businessDesc")}`,
                        },
                    ]}
                />
                <StandardButton
                    data-testid="continue-button"
                    isLoading={isLoading}
                    type="submit"
                    w="full"
                    variant={ENUM_BUTTON_VARIANTS.DARK_PURPLE_PRIMARY}
                    mt="3rem"
                >
                    {t("continue", { ns: "common" })}
                </StandardButton>
            </form>
        </Box>
    )
}
