import { Box, Text, VStack } from "@chakra-ui/react"
import { Option } from "@components/ui/types"
import { REGISTRATION_STEP, REG_SCREENS } from "@screens/onboarding/types"
import StandardButton from "components/ui/buttons/standard"
import { ENUM_BUTTON_VARIANTS } from "components/ui/buttons/standard/types"
import Select from "components/ui/select"
import TextArea from "components/ui/textArea"
import useFetchWrapper, { Method } from "hooks/useFetchWrapper"
import React from "react"
import { useForm } from "react-hook-form"
import { useTranslation } from "react-i18next"
import { SubScreenProps } from "screens/onboarding"
import AmplitudeClient from "sdks/amplitude"

type FormData = {
    onboarding_survey: Option<string>
    onboarding_survey_details: string
}

export default function YourName({ submitSubForm, userObj, setUserObj, isLoading }: SubScreenProps) {
    const { handleSubmit, control, watch, setValue } = useForm<FormData>(formatDefaultValues())
    const { t } = useTranslation(["onboarding", "common"])
    const { fetchWrapper } = useFetchWrapper()

    const [surveyOptions, setSurveyOptions] = React.useState<Option<string>[]>([] as Option<string>[])

    // only sets default value of 1 of the 2 fields here. The other field is set optionally in setDefaultValue()
    function formatDefaultValues() {
        return {
            defaultValues: {
                onboarding_survey_details: userObj?.onboarding_survey_details || "",
            },
        }
    }

    React.useEffect(() => {
        ;(async () => {
            const res = await fetchWrapper(
                `/api/v3/account/options/survey/?country=${userObj?.country}&state=${userObj?.region}`,
                Method.GET
            )
            if (res?.success) {
                const arr: Option<string>[] = []
                for (const key in res) {
                    if (key !== "success") {
                        const value = res[key].source
                        arr.push({ value, label: value })
                    }
                }
                setSurveyOptions(arr)
                setDefaultValue(arr)
            }
        })()
    }, [])

    // Setting default values is done differently here than the other screens because the referral options are fetched from the server
    function setDefaultValue(arr: Option<string>[]) {
        if (!userObj?.onboarding_survey) return

        const _option = arr.find((occ) => {
            const val = userObj?.onboarding_survey
            return val === occ.value
        })

        if (_option) {
            setValue("onboarding_survey", {
                value: _option.value,
                label: _option.label,
            })
        }
    }

    async function onSubmit(values: FormData) {
        const onboardingSurvey = values.onboarding_survey.value
        const onboardingSurveyDetails = values.onboarding_survey_details
        await submitSubForm("/api/v2/account", { onboardingSurvey, onboardingSurveyDetails }, () => {
            setUserObj((prev) => {
                if (!prev) return prev
                return {
                    ...prev,
                    onboardingSurvey,
                    onboardingSurveyDetails,
                }
            })
            AmplitudeClient.logRegistrationEvent(REG_SCREENS.HowDidYouHear, REGISTRATION_STEP.REFERRAL)
        })
    }

    return (
        <Box w="full">
            <form onSubmit={handleSubmit(onSubmit)}>
                <VStack rowGap="1rem" alignItems={"flex-start"}>
                    <Text as="span" textStyle="InterTitle">
                        {t("howDidYouHear.header")}
                    </Text>
                    <Select
                        name="onboarding_survey"
                        options={surveyOptions}
                        control={control}
                        placeholder={surveyOptions.length ? undefined : t("loading", { ns: "common" })}
                        disabled={!surveyOptions.length}
                        rules={{ required: t("error.required", { ns: "common" }) }}
                        size="lg"
                    />
                    <TextArea
                        name="onboarding_survey_details"
                        control={control}
                        maxLength={150}
                        currentLength={watch("onboarding_survey_details")?.length}
                        label={t("howDidYouHear.referralDetails")}
                    />
                    <StandardButton
                        data-testid="continue-button"
                        type="submit"
                        variant={ENUM_BUTTON_VARIANTS.DARK_PURPLE_PRIMARY}
                        mt="1.5rem"
                        isLoading={isLoading}
                    >
                        {t("continue", { ns: "common" })}
                    </StandardButton>
                </VStack>
            </form>
        </Box>
    )
}
