import { Stack, StackProps } from "@chakra-ui/react"
import BreadCrumb, { BreadCrumbProps } from "../breadcrumb"

type Props = {
    breadCrumbs: Omit<BreadCrumbProps, "hasPrevious">[]
} & StackProps

export default function BreadCrumbGroup({ breadCrumbs, ...rest }: Props) {
    return (
        <Stack direction={"row"} {...rest}>
            {breadCrumbs.map((breadCrumb, index) => (
                <BreadCrumb key={index + breadCrumb.text} {...breadCrumb} hasPrevious={index !== 0} />
            ))}
        </Stack>
    )
}
