import StandardButton from "@/components/ui/buttons/standard"
import { ENUM_BUTTON_VARIANTS } from "@/components/ui/buttons/standard/types"
import RadioGroup from "@/components/ui/radio"
import Select from "@/components/ui/select"
import useFetchWrapper, { Method } from "@/hooks/useFetchWrapper"
import AmplitudeClient from "@/sdks/amplitude"
import { formatDefaultLocationValues, RegionOptions } from "@/util/optionsFormatted"
import { establishOnboardingSteps } from "@/util/verificationSteps"
import { Box, Text } from "@chakra-ui/layout"
import { useToast } from "@chakra-ui/react"
import { Option } from "@components/ui/types"
import { AccountRes } from "@screens/landing/types"
import React from "react"
import { useForm } from "react-hook-form"
import { useTranslation } from "react-i18next"
import { SubScreenProps } from ".."
import { SurveyData } from "../surveys/types"
import { REG_SCREENS } from "../types"
import { checkIfBannedState } from "../whereIncorporated"
import NotPermittedState from "../whereIncorporated/notPermittedState"

type FormData = {
    country: Option<string>
    region: Option<string>
}

type Props = {
    setIsLoading: React.Dispatch<React.SetStateAction<boolean>>
    setPhases: React.Dispatch<React.SetStateAction<REG_SCREENS[]>>
    setCurrentPhase: React.Dispatch<React.SetStateAction<{ name?: REG_SCREENS; num?: number }>>
    doSurveyData: (_data: AccountRes) => Promise<SurveyData[]>
    countSurveyScreens: (_surveyData: SurveyData[]) => number
    phases: REG_SCREENS[]
} & SubScreenProps

export default function WhereReside({
    isLoading,
    setUserObj,
    userObj,
    setPhases,
    countSurveyScreens,
    doSurveyData,
    setCurrentPhase,
    setIsLoading,
    phases,
}: Props) {
    const { t } = useTranslation(["onboarding", "common"])
    const toast = useToast()
    const { fetchWrapper } = useFetchWrapper()
    const { handleSubmit, control, watch, getValues, setValue } = useForm<FormData>({
        defaultValues:
            userObj?.country && userObj.region
                ? formatDefaultLocationValues(userObj.country, userObj.region)
                : { country: { value: "CA", label: t("canada", { ns: "common" }) } },
    })

    const COUNTRY_OPTIONS = [
        { value: "CA", label: t("canada", { ns: "common" }) },
        { value: "US", label: t("usa", { ns: "common" }) },
    ]

    const [isNotPermittedState, toggleNotPermittedState] = React.useState(false)

    // Forces region to clear on country change
    React.useEffect(() => {
        // @ts-expect-error Set value as null
        setValue("region", null)
    }, [getValues("country")?.value])

    function toastServerError() {
        toast({
            title: t("error.server", { ns: "common" }),
            description: t("error.serverDesc", { ns: "common" }),
            status: "error",
            duration: 10000,
        })
    }

    const onSubmit = async (values: FormData) => {
        if (checkIfBannedState(values.country.value, values.region?.value as string)) {
            toggleNotPermittedState(true)
        } else {
            const payload = {
                country: values.country.value,
                region: values.region.value,
            }
            setIsLoading(true)
            fetchWrapper("/api/v2/account", Method.POST, payload)
                .then(async (data: { data: AccountRes }) => {
                    const _data = data.data as AccountRes
                    const _surveys = await doSurveyData(_data)
                    const surveyCount = countSurveyScreens(_surveys)
                    const newPhases: REG_SCREENS[] = establishOnboardingSteps(_data, surveyCount)
                    const prevPhasesLength = phases.length
                    setPhases((prev) => [...prev, ...newPhases])
                    setUserObj(_data)
                    setTimeout(() => {
                        // Start at the beginnging of the new phases even though its concattenated with the previous phases
                        setCurrentPhase({ name: newPhases[0], num: prevPhasesLength })
                    }, 0)
                    AmplitudeClient.logEvent(REG_SCREENS.WhereReside)
                })
                .catch(() => {
                    toastServerError()
                })
                .finally(() => setIsLoading(false))
        }
    }

    const uuidKey = React.useMemo(() => {
        return crypto.getRandomValues(new Uint32Array(1))[0]
    }, [])

    return (
        <Box w="full">
            {isNotPermittedState ? (
                <NotPermittedState
                    toastServerError={toastServerError}
                    region={watch("region")?.value as "NY" | "HI" | "LA"}
                    email={userObj?.email as string}
                    goBack={() => toggleNotPermittedState(false)}
                />
            ) : (
                <>
                    <Text textStyle="InterTitle" mb="0.75rem">
                        {t("whereReside.title")}
                    </Text>
                    <form onSubmit={handleSubmit(onSubmit)}>
                        <Box display="flex" flexDir="column" rowGap="1.5rem">
                            <Text textStyle="InterSmall">{t("whereReside.description")}</Text>
                            <RadioGroup
                                control={control}
                                name="country"
                                options={COUNTRY_OPTIONS}
                                defaultOption={{ value: "CA", label: "Canada" }}
                                isHorizontal={false}
                            />
                            <Select
                                key={uuidKey}
                                name="region"
                                label={
                                    watch("country")?.value === "CA"
                                        ? t("whereReside.selectProvince")
                                        : t("whereReside.selectState")
                                }
                                options={
                                    watch("country")?.value === "CA" ? RegionOptions.provinces : RegionOptions.states
                                }
                                control={control}
                                rules={{ required: t("error.required", { ns: "common" }) }}
                                isDisabled={!watch("country")}
                            />
                            <StandardButton
                                data-testid="continue-button"
                                isLoading={isLoading}
                                type="submit"
                                variant={ENUM_BUTTON_VARIANTS.DARK_PURPLE_PRIMARY}
                                mt="1.5rem"
                            >
                                {t("continue", { ns: "common" })}
                            </StandardButton>
                        </Box>
                    </form>
                </>
            )}
        </Box>
    )
}
