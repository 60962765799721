import { memo, useMemo } from "react"
import { Box, Td, Text, Tr, Image, useBreakpointValue } from "@chakra-ui/react"
import Star from "assets/svgs/common/star"
import Chevron from "assets/svgs/arrows/chevron"
import useImage from "hooks/useImage"
import StarFilled from "assets/svgs/common/star-filled"
import { AssetDetails } from "@redux/assetsDetails/types"
import { addCommasToNumber, shortenLargeNumber } from "@util/stringFormatting"

type Props = {
    coin: AssetDetails
    isFavourite: boolean
    handleViewMore: (_: string) => void
    isLast: boolean
}

const CoinTradingRow = ({
    coin: { name, symbol, statistics, price, "24h_percent": hr24Percent },
    isFavourite,
    handleViewMore,
    isLast,
}: Props) => {
    const isDesktop = useBreakpointValue({ base: false, lg: true })
    const { loading, error, image } = useImage("coins/" + symbol.toLowerCase() + "-mobile.png")

    const change24Color = useMemo(() => {
        return hr24Percent > 0 ? "green.90" : hr24Percent < 0 ? "red.100" : "grey.70"
    }, [hr24Percent])

    const borderBottom = useMemo(() => {
        return isLast ? "none !important" : "1px solid #E5E5EA"
    }, [isLast])

    return (
        <Tr
            w="full"
            py="0.5rem"
            cursor="pointer"
            textStyle="ManropeSemiBold"
            _hover={{
                bg: "grey.10",
            }}
        >
            <Td borderBottom={borderBottom} p={{ base: "0.5rem", sm: "1rem" }}>
                <Box display="inline-block" mr={{ base: "0.25rem", lg: "0.75rem" }} verticalAlign={"middle"}>
                    <Box role="group">
                        <Box
                            _groupHover={{
                                display: "none",
                            }}
                        >
                            {isFavourite ? <StarFilled color="#FFC255" /> : <Star color="#AEAEB2" />}
                        </Box>
                        <Box
                            _groupHover={{
                                display: "inline-block",
                            }}
                            display="none"
                        >
                            {isFavourite ? <Star color="#FFC255" /> : <StarFilled color="#FFC255" />}
                        </Box>
                    </Box>
                </Box>
                <Box display="inline-block" mr="0.75rem" verticalAlign={"middle"}>
                    {image && (
                        <Image
                            src={image}
                            alt={"coin" + symbol.toLowerCase()}
                            height={{ base: "28px", lg: "42px" }}
                            width={{ base: "28px", lg: "42px" }}
                        />
                    )}
                </Box>
                <Box onClick={() => handleViewMore(symbol)} display="inline-block" verticalAlign={"middle"}>
                    {isDesktop ? (
                        <Text>
                            <Text
                                as="span"
                                whiteSpace={{ base: "pre-wrap", md: "nowrap" }}
                                mr={{ base: 0, lg: "0.5rem" }}
                            >
                                {name}
                            </Text>
                            <Text as="span" textStyle="ManropeTiny" color="grey.light.50">
                                {symbol}
                            </Text>
                        </Text>
                    ) : (
                        <Text>
                            <Text whiteSpace={{ base: "pre-wrap", md: "nowrap" }} mr={{ base: 0, lg: "0.5rem" }}>
                                {name}
                            </Text>
                            <Text textStyle="ManropeTiny" color="grey.light.50">
                                {symbol}
                            </Text>
                        </Text>
                    )}
                </Box>
            </Td>
            <Td onClick={() => handleViewMore(symbol)} borderBottom={borderBottom}>
                ${addCommasToNumber(Number(price))}
            </Td>
            <Td
                onClick={() => handleViewMore(symbol)}
                borderBottom={borderBottom}
                display={{ base: "none", smmd: "table-cell" }}
                color={change24Color}
            >
                {hr24Percent > 0 ? "+" : ""}
                {hr24Percent}%
            </Td>
            <Td
                onClick={() => handleViewMore(symbol)}
                borderBottom={borderBottom}
                display={{ base: "none", md: "table-cell" }}
            >
                ${shortenLargeNumber(statistics.market_cap_cad ?? (statistics.market_cap_usd as number))}
            </Td>
            <Td
                borderBottom={borderBottom}
                onClick={() => handleViewMore(symbol)}
                color="blue.100"
                display={{ base: "none", lg: "table-cell" }}
                textAlign={"right"}
            >
                <Text whiteSpace="pre-wrap" display="inline-block" verticalAlign={"middle"} mr="0.5rem">
                    View More
                </Text>
                <Box display="inline-block" verticalAlign={"middle"}>
                    <Chevron color="#3D65F6" />
                </Box>
            </Td>
        </Tr>
    )
}

export default memo(CoinTradingRow)
