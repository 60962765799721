import { Box, Text, useTheme, useToast, VStack } from "@chakra-ui/react"
import React from "react"
import { useForm } from "react-hook-form"
import StandardButton from "components/ui/buttons/standard"
import { ENUM_BUTTON_VARIANTS } from "components/ui/buttons/standard/types"
import TextInput from "components/ui/textInput"
import Arrow from "assets/svgs/arrows/arrow"
import Email from "assets/svgs/email"
import useFetchWrapper, { Method } from "hooks/useFetchWrapper"
import NetcoinsLogo from "assets/svgs/netcoins/netcoins-logo"
import NetcoinsLogoText from "assets/svgs/netcoins/netcoins-logo-text"
import AmplitudeClient from "sdks/amplitude"
import { LOGIN_SCREENS } from "@screens/onboarding/types"
import { useTranslation } from "react-i18next"

type FormData = {
    email: string
}

type Props = {
    setIsForgotPassword: React.Dispatch<React.SetStateAction<boolean>>
}

export default function ForgotPassword({ setIsForgotPassword }: Props) {
    const theme = useTheme()
    const toast = useToast()
    const { t } = useTranslation(["login", "common"])
    const { fetchWrapper } = useFetchWrapper()
    const { handleSubmit, control } = useForm<FormData>()

    const [loading, setLoading] = React.useState(false)

    // analytics
    React.useEffect(() => {
        AmplitudeClient.logScreenEvent(LOGIN_SCREENS.ForgotPassword)
    }, [])

    const onSubmit = (values: FormData) => {
        setLoading(true)
        fetchWrapper("/password/email", Method.POST, values, true, {
            "X-CSRF-TOKEN": document.head.querySelector('meta[name="csrf-token"]')?.getAttribute("content") ?? "",
        })
            .then(() => {
                if (!toast.isActive("success")) {
                    toast({
                        id: "success",
                        title: t("passwordRecovery.toastTitleSuccess"),
                        description: t("passwordRecovery.toastSuccess"),
                        status: "info",
                        isClosable: true,
                        duration: 100000,
                    })
                }
            })
            .catch(() => {
                if (!toast.isActive("error")) {
                    toast({
                        id: "error",
                        title: t("passwordRecovery.toastTitleErr"),
                        description: t("passwordRecovery.toastErr"),
                        status: "error",
                        isClosable: true,
                        duration: 100000,
                    })
                }
            })
            .finally(() => setLoading(false))
    }

    return (
        <>
            <VStack spacing="1.5rem" display="flex" flexGrow={1} w="full">
                <VStack py="1.5rem" spacing="1.5rem">
                    <NetcoinsLogo color="white" width={50} />
                    <NetcoinsLogoText color="white" width={200} />
                </VStack>
                <Box display="flex" flexDir="column" rowGap="0.5rem">
                    <Text color="white" textStyle="InterSubtitle">
                        {t("passwordRecovery.title")}
                    </Text>
                    <Text color="bluePurple.30" textStyle="InterRegular">
                        {t("passwordRecovery.subtitle")}
                    </Text>
                </Box>
                <form onSubmit={handleSubmit(onSubmit)} style={{ width: "100%" }}>
                    <Box display="flex" flexDir="column" rowGap="1.5rem" mb="1.5rem" w="full">
                        <TextInput
                            baseColor="bluePurple.80"
                            control={control}
                            name="email"
                            rules={{ required: t("error.required", { ns: "common" }) }}
                            placeholder={t("passwordRecovery.emailPlaceholder")}
                            size="lg"
                            leftIcon={<Email color={theme.colors.grey[20]} width={20} />}
                        />
                        <StandardButton
                            data-testid="send-link-button"
                            w="full"
                            type="submit"
                            variant={ENUM_BUTTON_VARIANTS.BLUE_PRIMARY}
                            isLoading={loading}
                        >
                            {t("passwordRecovery.sendLink")}
                        </StandardButton>
                    </Box>
                </form>
                <Box
                    cursor="pointer"
                    w="full"
                    display="flex"
                    columnGap="0.75rem"
                    onClick={() => setIsForgotPassword(false)}
                >
                    <Arrow size={24} color={theme.colors.blue[70]} />
                    <Text color="blue.70" textStyle="InterRegular">
                        {t("passwordRecovery.return")}
                    </Text>
                </Box>
            </VStack>
        </>
    )
}
