// useAuth.ts (custom hook)
import { useState, useEffect } from "react"

export const useAuth = () => {
    const [isAuthenticated, setIsAuthenticated] = useState<boolean>(true)

    // TODO: Would have conditional logged-in logic here: i.e: having a token/passing other conditions.
    // This logic should be added with Redux/State management system

    return {
        isAuthenticated,
    }
}
