export default function CreditCard({ size = 24, color = "white" }: { size?: number; color?: string }) {
    return (
        <svg width={size} height={size} viewBox="0 0 24 24" fill={color} xmlns="http://www.w3.org/2000/svg">
            <g id="MdPayment">
                <path
                    id="Vector"
                    d="M20 8.0061H4V6.0061H20V8.0061ZM20 18.0061H4V12.0061H20V18.0061ZM20 4.0061H4C2.89 4.0061 2 4.8961 2 6.0061V18.0061C2 18.5365 2.21071 19.0452 2.58579 19.4203C2.96086 19.7954 3.46957 20.0061 4 20.0061H20C20.5304 20.0061 21.0391 19.7954 21.4142 19.4203C21.7893 19.0452 22 18.5365 22 18.0061V6.0061C22 4.8961 21.1 4.0061 20 4.0061Z"
                    fill={color}
                />
            </g>
        </svg>
    )
}
