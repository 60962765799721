import { InMemoryWebStorage, UserManager, WebStorageStateStore, User as oidcUser } from "oidc-client-ts"
import React from "react"

type AuthServiceConfig = {
    oauth_provider: string
    oauth_path: string
    oauth_metadata_path: string
    redirect_uri: string
    post_logout_redirect_uri: string
    clientId: string
    scope?: string
}

type Props = {
    children: React.ReactNode
}

type State = {
    userManager: UserManager
    user: oidcUser | null
    // deviceId: string,
    isRefreshing: boolean
}

const scope = "default" // todo give this real name
const clientId = process.env.REACT_APP_OAUTH_CLIENT_ID as string
// const deviceId = "123"; // Not required for refresh grants.

const appUrl = window.location.protocol + "//" + window.location.host
const oauthUrl = process.env.REACT_APP_BASE_URL as string

const CONFIG: AuthServiceConfig = {
    clientId,
    scope,
    oauth_provider: oauthUrl,
    oauth_path: "/oauth",
    oauth_metadata_path: "/.well-known/oauth-authorization-server",
    redirect_uri: appUrl,
    post_logout_redirect_uri: oauthUrl + "/logout",
}

type Context = {
    user: oidcUser | null
    renewToken: () => Promise<oidcUser | null>
    logout: () => Promise<void>
}
export const UserContext = React.createContext<Context>({
    user: null,
    renewToken: async () => null,
    // eslint-disable-next-line @typescript-eslint/no-empty-function
    logout: async () => {},
})

/**
 * Manages authentication.
 * Abstracts over oidc-client-ts UserManager.
 */

export default class AuthUserProvider extends React.Component<Props, State> {
    constructor(props: Props) {
        super(props)
        const settings = {
            authority: CONFIG.oauth_provider + CONFIG.oauth_path,
            redirect_uri: CONFIG.redirect_uri,
            post_logout_redirect_uri: CONFIG.post_logout_redirect_uri,
            client_id: CONFIG.clientId,
            scope: CONFIG.scope,
            loadUserInfo: false,
            response_type: "code",
            metadataUrl: CONFIG.oauth_provider + CONFIG.oauth_metadata_path,
            revokeTokensOnSignout: true,
            // Can be used while backend does not require custom arg (device_id) for web.
            automaticSilentRenew: true,
            userStore: new WebStorageStateStore({ store: new InMemoryWebStorage() }),
        }

        this.logout = this.logout.bind(this)

        this.state = {
            userManager: new UserManager(settings),
            user: null,
            // deviceId: deviceId,
            isRefreshing: false,
        }
    }

    resolveUserOrLogin = async () => {
        const user = await this.getUser()
        if (user) {
            // already authorized
            this.setState((prev) => ({ ...prev, user }))
            return
        }

        // start or continue auth
        const stateExists = new URLSearchParams(window.location.search).has("state")
        if (stateExists) {
            // handle callback
            const _user = await this.handleSignInCallback()
            if (_user) {
                console.log(_user)
                this.setState((prev) => ({ ...prev, user: _user as oidcUser }))
                // discard oauth params
                history.replaceState(
                    {},
                    document.title,
                    window.location.protocol + "//" + window.location.host + window.location.pathname
                )
                return
            }
        }
        // (re)initiate auth
        await this.login()
    }

    componentDidMount(): void {
        this.resolveUserOrLogin()
    }

    /**
     * Resolve the current authenticated user.
     */
    getUser(): Promise<oidcUser | null> {
        return this.state.userManager.getUser()
    }

    /**
     * Initiates the sign-in process by redirecting to the identity provider's sign-in page.
     * @returns {Promise<void>} resolved when redirected.
     */
    login(): Promise<void> {
        return this.state.userManager.signinRedirect()
    }

    /**
     * Initiates a log out.
     * Optional revokes tokens as specified by (revokeTokensOnSignout)
     * And redirects to post_logout_redirect_uri.
     * @returns {Promise<void>} resolved when redirected.
     */
    async logout(): Promise<void> {
        return this.state.userManager.signoutRedirect()
    }

    /**
     * Handles the callback from identity provider after sign-in,
     *  exchanges authorization code for access & refresh tokens, and stores it.
     */
    handleSignInCallback(): Promise<void | oidcUser> {
        return this.state.userManager.signinCallback()
    }

    /**
     * Initiates silent access token refresh via refresh token.
     */
    renewToken = async (): Promise<oidcUser | null> => {
        if (this.state.isRefreshing) {
            // wait for current refresh to resolve.
            return new Promise((resolve) => {
                const interval = setInterval(() => {
                    if (!this.state.isRefreshing) {
                        clearInterval(interval)
                        resolve(this.state.user)
                    }
                }, 100)
            })
        }
        this.setState((prev) => ({ ...prev, isRefreshing: true }))
        const args = {
            extraTokenParams: {
                // device_id: this.state.deviceId,
            },
        }
        const user = await this.state.userManager.signinSilent(args)
        this.setState((prev) => ({ ...prev, user, isRefreshing: false }))
        return this.state.user
    }

    render() {
        if (!this.state.user) {
            return <></>
        }

        return (
            <UserContext.Provider
                value={{
                    user: this.state.user,
                    renewToken: this.renewToken,
                    logout: this.logout,
                }}
            >
                {this.props.children}
            </UserContext.Provider>
        )
    }
}
