import { Input, InputGroup, InputLeftAddon } from "@chakra-ui/react"
import { FIELD_SIZE } from "@components/ui/types"
import Search from "assets/svgs/common/search"
import React, { useMemo } from "react"
import useColorFormatConverter from "theme/useColorFormatConverter"
import SEARCHBAR_VARIANTS, { ENUM_SEARCHBAR_VARIANTS } from "./types"

export type Props = {
    value?: string
    onChange: (_: string) => void
    variant?: ENUM_SEARCHBAR_VARIANTS
    placeholder?: string
    size?: "sm" | "md" | "lg"
    disabled?: boolean
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    [rest: string]: any
}

export default function Searchbar({
    value,
    onChange,
    variant = ENUM_SEARCHBAR_VARIANTS.PRIMARY,
    placeholder,
    size,
    disabled,
    ...rest
}: Props) {
    const colorConverter = useColorFormatConverter()

    const palette = useMemo(() => SEARCHBAR_VARIANTS[variant], [variant])

    const getBorderColor = React.useCallback(
        (isFocused: boolean) => {
            if (isFocused) {
                return "blue.100"
            }
            return "transparent"
        },
        [palette]
    )

    return (
        <InputGroup
            _focusWithin={{
                borderColor: getBorderColor(true),
                ".chakra-input__left-addon": {
                    borderColor: `${palette.normal.borderColor} !important`,
                },
                ".chakra-input": {
                    borderTopColor: `${palette.normal.borderColor} !important`,
                    borderBottomColor: `${palette.normal.borderColor} !important`,
                    borderLeftColor: `transparent !important`,
                    borderRightColor: `${palette.normal.borderColor} !important`,
                },
            }}
            _hover={{
                borderColor: palette.hover.borderColor,
            }}
            borderColor={getBorderColor(false)}
            borderWidth="1px"
            borderRadius="8px"
            h={`${FIELD_SIZE[size ?? "lg"]}px`}
            {...rest}
        >
            <InputLeftAddon
                pointerEvents="none"
                borderColor={palette?.normal.borderColor}
                h={`${FIELD_SIZE[size ?? "lg"] - 2}px`}
                borderTopWidth="1px"
                borderBottomWidth="1px"
                borderLeftWidth={"1px"}
                borderRightWidth={0}
                bgColor={palette.normal.bg}
                paddingRight={"0 !important"}
            >
                <Search color="black" />
            </InputLeftAddon>
            <Input
                h={`${FIELD_SIZE[size ?? "lg"] - 2}px`}
                data-testid={`input-${name}`}
                value={value}
                onChange={(e) => onChange(e.target.value)}
                disabled={disabled}
                placeholder={placeholder ?? "Search"}
                _placeholder={{ opacity: 0.4, color: palette?.normal.placeholder }}
                fontFamily="Inter"
                fontSize="16px"
                fontStyle="normal"
                fontWeight="400"
                lineHeight="150%"
                bgColor={palette.normal.bg}
                color={palette.normal.color}
                borderColor={palette.normal.borderColor}
                _disabled={{
                    borderColor: palette?.disabled.borderColor,
                }}
                borderTopWidth="1px"
                borderBottomWidth="1px"
                borderLeftWidth={0}
                borderRightWidth={"1px"}
                focusBorderColor="transparent"
                errorBorderColor="transparent"
                borderTopLeftRadius={0}
                borderBottomLeftRadius={0}
                borderBottomRightRadius={"8px"}
                borderTopRightRadius={"8px"}
                _autofill={{
                    WebkitTextFillColor: colorConverter(palette.normal.color),
                    WebkitBoxShadow: `inset 0 0 20px 20px ${
                        palette.normal.bg === "white" || palette.normal.bg === "black"
                            ? palette.normal.bg
                            : colorConverter(palette.normal.bg)
                    }`,
                }}
            />
        </InputGroup>
    )
}
