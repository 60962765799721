type Props = {
    width: number
    color?: string // can also be set through css by stating the fill property on the svg or path tag
}

export default function Check({ width, color }: Props) {
    return (
        <svg width={width} height={width} viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path d="M21 6.99984L9 18.9998L3.5 13.4998L4.91 12.0898L9 16.1698L19.59 5.58984L21 6.99984Z" fill={color} />
        </svg>
    )
}
