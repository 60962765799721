export function formatPostalCode(country: string, postalCode: string): string {
    let _postalCode = postalCode.trim()
    if (country === "CA") {
        _postalCode = postalCode.toUpperCase().replace(/-/g, " ") // remove hyphens and make it uppercase
        if (postalCode.length === 6) {
            return _postalCode.substring(0, 3) + " " + _postalCode.substring(3) // add a space after the 3rd character
        }
        return _postalCode
    } else {
        return _postalCode // here its a zip code that doesn't need to be formatted
    }
}

export function addCommasToNumber(num: number): string {
    const numStr = num.toString()
    const parts = numStr.split(".")
    parts[0] = parts[0].replace(/\B(?=(\d{3})+(?!\d))/g, ",")
    return parts.join(".")
}

export function shortenLargeNumber(num: number): string {
    const abbreviations = [
        { value: 1e12, symbol: "T" },
        { value: 1e9, symbol: "B" },
        { value: 1e6, symbol: "M" },
        { value: 1e3, symbol: "K" },
    ]

    const absNum = Math.abs(num)

    for (const { value, symbol } of abbreviations) {
        if (absNum >= value) {
            // Divide by the value and round to 3 decimal places
            const shortened = (num / value).toFixed(1)
            // Remove trailing zeros after the decimal point
            const formatted = parseFloat(shortened).toString()
            return `${formatted} ${symbol}`
        }
    }

    // If the number is smaller than 1000, return it as is
    return num.toString()
}
