export default function Star({ size = 24, color = "white" }: { size?: number; color?: string }) {
    return (
        <svg width={size} height={size} viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path
                d="M10 12.825L6.86669 14.7167L7.69169 11.15L4.92502 8.75L8.57502 8.44166L10 5.075L11.425 8.44166L15.075 8.75L12.3084 11.15L13.1334 14.7167L10 12.825ZM18.3334 7.7L12.3417 7.19166L10 1.66666L7.65835 7.19166L1.66669 7.7L6.20835 11.6417L4.85002 17.5L10 14.3917L15.15 17.5L13.7834 11.6417L18.3334 7.7Z"
                fill={color}
            />
        </svg>
    )
}
