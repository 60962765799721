import MarketDown from "@assets/svgs/arrows/market-down"
import MarketUp from "@assets/svgs/arrows/market-up"
import Minus from "@assets/svgs/common/minus"
import Plus from "@assets/svgs/common/plus"
import { Box, Text } from "@chakra-ui/react"
import BreadCrumbGroup from "@components/ui/breadcrumbs/group"
import StandardButton from "@components/ui/buttons/standard"
import { ENUM_BUTTON_VARIANTS } from "@components/ui/buttons/standard/types"
import useFetchWrapper from "@hooks/useFetchWrapper"
import useImage from "@hooks/useImage"
import { useGetAssetsDetailsQuery } from "@redux/assetsDetails/apiSlice"
import { ROUTES } from "@routing/routes"
import React, { useMemo, useState } from "react"
import { useTranslation } from "react-i18next"
import { useNavigate, useParams } from "react-router"
import DrawerCoinTrade from "../trade/drawerCoinTrade"
import AssetStats from "./assetStats"
import MarketDetailsGraph from "./graph"

export default function MarketDetails() {
    const { t } = useTranslation(["marketDetails", "common"])
    const { asset } = useParams()
    const navigate = useNavigate()
    const { fetchWrapper } = useFetchWrapper()
    const { data: assetDetailsData, error } = useGetAssetsDetailsQuery(undefined)
    const { image, loading, error: imgError } = useImage("coins/" + asset?.toLowerCase() + "-mobile.png")

    const [isDrawerOpen, toggleDrawerOpen] = useState(false)
    const [incDrawerTab, setIncDrawerTab] = useState<"buy" | "sell">()

    function openDrawer(isBuying: boolean) {
        setIncDrawerTab(isBuying ? "buy" : "sell")
        toggleDrawerOpen(true)
    }

    const data = useMemo(() => {
        if (assetDetailsData && asset) {
            return assetDetailsData?.[asset]
        }
        return null
    }, [assetDetailsData])

    const MarketIcon = useMemo(() => {
        const val: number | undefined = data?.["24h_percent"]
        if (val === undefined || val === 0) return null

        return val > 0 ? <MarketUp /> : <MarketDown />
    }, [data])

    const MarketColor = useMemo(() => {
        const val: number | undefined = data?.["24h_percent"]
        if (val === undefined || val === 0) return "#636366"

        return val > 0 ? "#1B881F" : "#C62828"
    }, [data])

    const assetName = "Bitcoin"
    const marketStats = {
        volume24h: "$40.23 B",
        low24h: "$89,936.33",
        high24h: "$91,972.69",
        marketCap: "$1.3 T",
        maxSupply: "21M BTC",
        circulatingSupply: "$1.3 T",
    }

    const aboutAsset = {
        description: `${assetName} is a decentralized digital currency, without a central bank or single administrator, that can be sent from user to user on the peer-to-peer bitcoin network without the need for intermediaries.`,
        learnMoreLink: "#",
    }

    const transactionTableData = [
        {
            label: "Open Orders",
            data: [
                { date: "Sept 04, 2024", type: "Buy", price: "$99,999,999,999.99", amount: "99,999,999.99 MMMM" },
                { date: "Sept 04, 2024", type: "Sell", price: "$99,999,999,999.99", amount: "99,999,999.99 MMMM" },
            ],
        },
        {
            label: "Staked Orders",
            data: [
                { date: "Sept 05, 2024", type: "Stake", price: "$88,888,888,888.88", amount: "88,888,888.88 MMMM" },
                { date: "Sept 06, 2024", type: "Unstake", price: "$77,777,777,777.77", amount: "77,777,777.77 MMMM" },
            ],
        },
        {
            label: "Activity",
            data: [
                { date: "Sept 07, 2024", type: "Deposit", price: "N/A", amount: "55,555,555.55 MMMM" },
                { date: "Sept 08, 2024", type: "Withdraw", price: "N/A", amount: "44,444,444.44 MMMM" },
            ],
        },
    ]

    return (
        <Box>
            <BreadCrumbGroup
                mb="0.5rem"
                breadCrumbs={[
                    { text: "Trade", onClick: () => navigate(ROUTES.DASHBOARD_TRADE) },
                    { text: data?.name ?? "" },
                ]}
            />
            <Box
                display="flex"
                justifyContent={"space-between"}
                alignItems={{ base: undefined, md: "flex-end" }}
                flexDir={{ base: "column", md: "row" }}
                pb={"3rem"}
                rowGap="1rem"
            >
                <Box display="flex" flex={1}>
                    <Box display="inline-block" mr="0.75rem" verticalAlign={"middle"}>
                        {image && <img src={image} alt={"coin-" + asset} height="60px" width="60px" />}
                    </Box>
                    <Box display="flex" flexDir={"column"}>
                        <Text textStyle="ManropeBody" whiteSpace={"nowrap"}>
                            {data?.name}&nbsp;
                            <Text as="span" color="grey.50">
                                {data?.symbol}
                            </Text>
                        </Text>
                        <Box display="flex" alignItems="flex-end" columnGap="0.75rem">
                            <Text textStyle="ManropeHeader" fontSize="24px">
                                ${data?.price}
                                {/* <Text>{}</Text> TODO user currency */}
                            </Text>
                            <Box display="flex" columnGap={"0.5rem"}>
                                {MarketIcon}
                                <Text whiteSpace={"nowrap"} color={MarketColor}>
                                    {data?.["24h_percent"]}
                                    <Text as="span">%</Text>
                                    <Text as="span" color={MarketColor}>{`(24h)`}</Text>
                                </Text>
                            </Box>
                        </Box>
                    </Box>
                </Box>
                <Box
                    display="flex"
                    flex={1}
                    // width={{ base: "100%", md: undefined }}
                    alignItems={"center"}
                    justifyContent={"flex-end"}
                    columnGap="0.5rem"
                >
                    <StandardButton
                        flexGrow={1}
                        type="button"
                        leftIcon={<Plus />}
                        variant={ENUM_BUTTON_VARIANTS.BLUE_PRIMARY}
                        onClick={() => openDrawer(true)}
                        alignSelf="flex-end"
                        size="xl"
                    >
                        {t("buy", { ns: "common" })}
                    </StandardButton>
                    <StandardButton
                        flexGrow={1}
                        type="button"
                        leftIcon={<Minus />}
                        variant={ENUM_BUTTON_VARIANTS.BLUE_PRIMARY}
                        onClick={() => openDrawer(false)}
                        alignSelf="flex-end"
                        size="xl"
                    >
                        {t("sell", { ns: "common" })}
                    </StandardButton>
                </Box>
            </Box>
            <MarketDetailsGraph asset={asset} />
            <DrawerCoinTrade
                coin={data}
                isOpen={isDrawerOpen}
                toggleSideDrawerOpen={() => toggleDrawerOpen(false)}
                openingTab={incDrawerTab}
            />
            <AssetStats
                assetName={assetName}
                marketStats={marketStats}
                aboutAsset={aboutAsset}
                transactions={transactionTableData}
            />
        </Box>
    )
}
