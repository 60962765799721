import React from "react"
import { Text, VStack, Box } from "@chakra-ui/react"
import RadioGroup from "components/ui/radio"
import { useForm } from "react-hook-form"
import StandardButton from "components/ui/buttons/standard"
import { ENUM_BUTTON_VARIANTS } from "components/ui/buttons/standard/types"
import AmplitudeClient from "sdks/amplitude"
import { REGISTRATION_STEP, REG_SCREENS } from "@screens/onboarding/types"
import { useTranslation } from "react-i18next"
import { SubScreenProps } from "screens/onboarding"
import { Option } from "@components/ui/types"
import { Identity, IdentityCitizenship } from "@screens/landing/types"

type FormData = {
    citizenship: Option<IdentityCitizenship>
}

export default function IsCanadian({
    submitSubForm,
    userObj,
    setUserObj,
    isLoading,
    setIsBackVisible,
}: SubScreenProps) {
    const [isNotCanadian, setIsNotCanadian] = React.useState(false)
    const { t } = useTranslation(["onboarding", "common"])

    const { handleSubmit, control } = useForm<FormData>({
        defaultValues: {
            citizenship:
                (userObj?.identity.citizenship && userObj?.identity.citizenship === IdentityCitizenship.CA_CITIZEN) ||
                userObj?.identity.citizenship === IdentityCitizenship.CA_RESIDENT
                    ? {
                          value: userObj?.identity.citizenship,
                          label: t(`isCanadian.${userObj?.identity.citizenship}`),
                      }
                    : undefined,
        },
    })

    async function onSubmit(values: FormData) {
        if (values?.citizenship?.value === IdentityCitizenship.NONE) {
            setIsBackVisible(false)
            setIsNotCanadian(true)
        } else {
            await submitSubForm(
                "/api/v2/account/identity",
                { citizenship: `ca_${values.citizenship.value}` }, // only works for Canadian. will need refactor for other countries
                () => {
                    setUserObj((prev) => {
                        if (!prev) return prev
                        return {
                            ...prev,
                            identity: {
                                ...prev.identity,
                                citizenship: values.citizenship.value as Identity["citizenship"],
                            },
                        }
                    })
                    AmplitudeClient.logRegistrationEvent(REG_SCREENS.IsCanadian, REGISTRATION_STEP.CANADA_CITIZENSHIP)
                }
            )
        }
    }

    return (
        <VStack>
            {isNotCanadian ? (
                <Box display="flex" flexDir="column" rowGap="1.5rem">
                    <Text textStyle="InterTitle">{t("isCanadian.deniedHeader")}</Text>
                    <Text textStyle="InterSmall">{t("isCanadian.deniedTitle")}</Text>
                    <StandardButton
                        data-testid="back-button"
                        onClick={() => {
                            setIsBackVisible(true)
                            setIsNotCanadian(false)
                        }}
                        type="button"
                        variant={ENUM_BUTTON_VARIANTS.DARK_PURPLE_PRIMARY}
                        mt="2.5rem"
                        w="full"
                    >
                        {t("goBack", { ns: "common" })}
                    </StandardButton>
                </Box>
            ) : (
                <form onSubmit={handleSubmit(onSubmit)}>
                    <Text textStyle="InterTitle" mb="1.5rem">
                        {t("isCanadian.header")}
                    </Text>
                    <RadioGroup
                        name="citizenship"
                        options={[
                            {
                                value: IdentityCitizenship.CA_CITIZEN,
                                label: t("isCanadian.canadian"),
                            },
                            {
                                value: IdentityCitizenship.CA_RESIDENT,
                                label: t("isCanadian.permanent"),
                            },
                            {
                                value: IdentityCitizenship.NONE,
                                label: t("isCanadian.neither"),
                            },
                        ]}
                        rules={{ required: t("error.requiredOption", { ns: "common" }) }}
                        isHorizontal={false}
                        control={control}
                    />
                    <StandardButton
                        data-testid="continue-button"
                        type="submit"
                        variant={ENUM_BUTTON_VARIANTS.DARK_PURPLE_PRIMARY}
                        mt="2.5rem"
                        w="full"
                        isLoading={isLoading}
                    >
                        {t("continue", { ns: "common" })}
                    </StandardButton>
                </form>
            )}
        </VStack>
    )
}
