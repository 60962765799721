import { Alert, AlertDescription, AlertIcon, Box, Text, useTheme, VStack } from "@chakra-ui/react"
import React, { FormEventHandler } from "react"
import { Control, useForm } from "react-hook-form"
import StandardButton from "components/ui/buttons/standard"
import { ENUM_BUTTON_VARIANTS } from "components/ui/buttons/standard/types"
import TextInput from "components/ui/textInput"
import Email from "assets/svgs/email"
import Lock from "assets/svgs/lock"
import NetcoinsLogo from "assets/svgs/netcoins/netcoins-logo"
import NetcoinsLogoText from "assets/svgs/netcoins/netcoins-logo-text"
import { useTranslation } from "react-i18next"

type FormData = {
    email: string
    password: string
}

type Props = {
    transition: () => void
    setIsForgotPassword: React.Dispatch<React.SetStateAction<boolean>>
    onSubmit: (email: string, password: string) => Promise<void>
    wasNewPasswordCreated: boolean
    isLoading: boolean
}

export default function Login({ transition, setIsForgotPassword, onSubmit, wasNewPasswordCreated, isLoading }: Props) {
    const theme = useTheme()
    const { t } = useTranslation(["login", "common"])
    const { handleSubmit, control } = useForm<FormData>()

    function submitForm(values: FormData) {
        onSubmit(values.email, values.password)
    }

    return (
        <>
            <VStack spacing="1.5rem" display="flex" flexGrow={1}>
                <VStack py="1.5rem" spacing="1.5rem">
                    <NetcoinsLogo color="white" width={50} />
                    <NetcoinsLogoText color="white" width={200} />
                </VStack>
                <Alert
                    status="success"
                    display={wasNewPasswordCreated ? "flex" : "none"}
                    mb={wasNewPasswordCreated ? "1.5rem" : 0}
                >
                    <AlertIcon />
                    <AlertDescription>{t("login.resetPasswordAlert")}</AlertDescription>
                </Alert>
                <form onSubmit={handleSubmit(submitForm)} style={{ width: "100%" }}>
                    <Box display="flex" flexDir="column" rowGap="1.5rem" alignItems={"flex-start"}>
                        <VStack w="full" alignItems={"start"} spacing="0.75rem">
                            <TextInput
                                baseColor="bluePurple.80"
                                control={control}
                                name="email"
                                rules={{ required: t("error.required", { ns: "common" }) }}
                                placeholder={t("login.emailPlaceholder")}
                                size="lg"
                                leftIcon={<Email color={theme.colors.grey[20]} width={20} />}
                            />
                            <TextInput
                                baseColor="bluePurple.80"
                                control={control}
                                name="password"
                                placeholder={t("login.passwordPlaceholder")}
                                rules={{ required: t("error.required", { ns: "common" }) }}
                                size="lg"
                                leftIcon={<Lock color={theme.colors.grey[20]} width={20} />}
                                isPassword
                            />
                            <Text
                                data-testid="text-forgotPassword"
                                cursor="pointer"
                                color="blue.70"
                                onClick={() => setIsForgotPassword(true)}
                            >
                                {t("login.forgotPassword")}
                            </Text>
                        </VStack>
                        <StandardButton
                            data-testid="login-button"
                            type="submit"
                            variant={ENUM_BUTTON_VARIANTS.BLUE_PRIMARY}
                            isLoading={isLoading}
                        >
                            {t("login.signIn")}
                        </StandardButton>
                        <Text textStyle="InterSmall" color="white">
                            {t("login.needAccount")}&nbsp;
                            <Text
                                data-testid="text-createAccount"
                                as="span"
                                onClick={transition}
                                color="blue.70"
                                cursor="pointer"
                            >
                                {t("login.createAccount")}
                            </Text>
                        </Text>
                        <Box
                            p="1.5rem"
                            borderRadius="4px"
                            display="flex"
                            flexDir="column"
                            rowGap="0.75rem"
                            bgColor="darkPurple.secondary"
                        >
                            <Text textStyle="InterSubtitleSmall" color="white">
                                {t("login.fraudsters")}
                            </Text>
                            <Text textStyle="InterTiny" color="white">
                                {t("login.moreFraudsters")}
                            </Text>
                        </Box>
                    </Box>
                </form>
            </VStack>
        </>
    )
}
