import { Box, Text } from "@chakra-ui/react"
import { useTranslation } from "react-i18next"
import { useForm } from "react-hook-form"
import { SubScreenProps } from ".."
import AmplitudeClient from "@/sdks/amplitude"
import { REGISTRATION_STEP, REG_SCREENS } from "../types"
import Checkbox from "@components/ui/checkbox"
import StandardButton from "@components/ui/buttons/standard"
import { ENUM_BUTTON_VARIANTS } from "@components/ui/buttons/standard/types"

type FormData = {
    zerohash_agreements: boolean
    netcoins_agreements: boolean
    understand: boolean
}

export default function ZeroHashDisclaimer({ submitSubForm, setUserObj }: SubScreenProps) {
    const { t } = useTranslation(["onboarding", "common"])
    const {
        control,
        handleSubmit,
        formState: { errors },
    } = useForm<FormData>()

    function handleAcknowledge() {
        submitSubForm(
            "/api/v2/account/disclaimer",
            {
                content_key: "zero_hash_service_en_us,zero_hash_privacy_and_regulatory_en_us,zero_hash_risk_en_us",
            },
            () => {
                setUserObj((prev) => {
                    if (!prev) return prev
                    return {
                        ...prev,
                        disclaimers: {
                            ...prev.disclaimers,
                            zero_hash_service: {
                                name: "zero_hash_service",
                                content_key: "zero_hash_service_en_us",
                                accepted_at: new Date(Date.now()),
                            },
                            zero_hash_privacy_and_regulatory: {
                                name: "zero_hash_privacy_and_regulatory",
                                content_key: "zero_hash_privacy_and_regulatory_en_us",
                                accepted_at: new Date(Date.now()),
                            },
                            zero_hash_risk: {
                                name: "zero_hash_risk",
                                content_key: "zero_hash_risk_en_us",
                                accepted_at: new Date(Date.now()),
                            },
                        },
                    }
                })
                AmplitudeClient.logRegistrationEvent(
                    REG_SCREENS.ZeroHashDisclaimer,
                    REGISTRATION_STEP.ZeroHashDisclaimer
                )
            }
        )
    }

    return (
        <Box w="full">
            <Text textStyle="InterTitle" mb="0.75rem">
                {t("zerohash.title")}
            </Text>
            <form onSubmit={handleSubmit(handleAcknowledge)}>
                <Box w="full" display="flex" flexDir="column" rowGap="1.5rem">
                    <Text textStyle="InterSmall">{t("zerohash.line1")}</Text>
                    <Text textStyle="InterSmall">{t("zerohash.line2")}</Text>
                    <Box>
                        <Box display="flex" columnGap="0.5rem" alignItems={"flex-start"}>
                            <Text flexGrow={1}>
                                <Text as="span" textStyle="InterSmall">
                                    {t("zerohash.line3")}&nbsp;
                                </Text>
                                <Text
                                    as="span"
                                    textStyle="InterSmall"
                                    cursor="pointer"
                                    color="blue.100"
                                    onClick={() =>
                                        window.open(
                                            "https://www.netcoins.com/en-us/files/terms-conditions-us.pdf",
                                            "_blank"
                                        )
                                    }
                                >
                                    {t("terms", { ns: "common" })}&nbsp;
                                </Text>
                                <Text as="span" textStyle="InterSmall">
                                    {t("zerohash.and")}&nbsp;
                                </Text>
                                <Text
                                    as="span"
                                    textStyle="InterSmall"
                                    cursor="pointer"
                                    color="blue.100"
                                    onClick={() =>
                                        window.open("https://www.netcoins.com/files/privacy-policy-us.pdf", "_blank")
                                    }
                                >
                                    {t("privacyPolicy", { ns: "common" })}.
                                </Text>
                            </Text>
                            <Checkbox
                                name="netcoins_agreements"
                                control={control}
                                rules={{ required: t("zerohash.errorCheckbox") }}
                            />
                        </Box>
                        {errors.netcoins_agreements && (
                            <Text textStyle="InterSmall" color="alert.error" mt="0.5rem">
                                {t("zerohash.errorCheckbox")}
                            </Text>
                        )}
                    </Box>
                    <Box>
                        <Box display="flex" columnGap="0.5rem" alignItems={"flex-start"}>
                            <Text flexGrow={1}>
                                <Text as="span" textStyle="InterSmall">
                                    {t("zerohash.line4")}&nbsp;
                                </Text>
                                <Text
                                    as="span"
                                    textStyle="InterSmall"
                                    cursor="pointer"
                                    color="blue.100"
                                    onClick={() => window.open("https://zerohash.com/netcoins", "_blank")}
                                >
                                    Zero Hash and Zero Hash Liquidity Services User Agreement,&nbsp;
                                </Text>
                                <Text as="span" textStyle="InterSmall">
                                    {t("zerohash.line5")}&nbsp;
                                </Text>
                                <Text
                                    as="span"
                                    textStyle="InterSmall"
                                    cursor="pointer"
                                    color="blue.100"
                                    onClick={() =>
                                        window.open("https://docs.zerohash.com/page/privacy-policy", "_blank")
                                    }
                                >
                                    Privacy Policy&nbsp;
                                </Text>
                                <Text as="span" textStyle="InterSmall">
                                    {t("zerohash.and")}&nbsp;
                                </Text>
                                <Text
                                    as="span"
                                    textStyle="InterSmall"
                                    cursor="pointer"
                                    color="blue.100"
                                    onClick={() =>
                                        window.open(
                                            "https://docs.zerohash.com/page/us-licenses-and-disclosures",
                                            "_blank"
                                        )
                                    }
                                >
                                    Regulatory Disclosures
                                </Text>
                            </Text>
                            <Checkbox
                                name="zerohash_agreements"
                                control={control}
                                rules={{ required: t("zerohash.errorCheckbox") }}
                            />
                        </Box>
                        {errors.zerohash_agreements && (
                            <Text textStyle="InterSmall" color="alert.error" mt="0.5rem">
                                {t("zerohash.errorCheckbox")}
                            </Text>
                        )}
                    </Box>
                    <Box>
                        <Box display="flex" columnGap="0.5rem" alignItems={"flex-start"}>
                            <Text as="span" textStyle="InterSmall" flexGrow={1}>
                                {t("zerohash.line6")}
                            </Text>
                            <Checkbox
                                name="understand"
                                control={control}
                                rules={{ required: t("zerohash.errorCheckbox") }}
                            />
                        </Box>
                        {errors.understand && (
                            <Text textStyle="InterSmall" color="alert.error" mt="0.5rem">
                                {t("zerohash.errorCheckbox")}
                            </Text>
                        )}
                    </Box>
                </Box>
                <StandardButton
                    data-testid="continue-button"
                    type="submit"
                    variant={ENUM_BUTTON_VARIANTS.DARK_PURPLE_PRIMARY}
                    mt="3rem"
                    w="full"
                >
                    {t("continue", { ns: "common" })}
                </StandardButton>
            </form>
        </Box>
    )
}
