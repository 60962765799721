export enum ENUM_BUTTON_VARIANTS {
    DARK_PURPLE_PRIMARY = "DARK_PURPLE_PRIMARY",
    DARK_PURPLE_BORDERLESS = "DARK_PURPLE_BORDERLESS",
    WHITE = "WHITE",
    WHITE_BORDERLESS = "WHITE_BORDERLESS",
    BLUE_PRIMARY = "BLUE_PRIMARY",
    SECONDARY_OUTLINE = "SECONDARY_OUTLINE",
    SECONDARY_GHOST = "SECONDARY_GHOST",
}

type STATEFUL_PROPERTIES = {
    bg: string
    borderColor: string
    color: string
}

type STRUCTURE_BUTTON_VARIANT = {
    normal: STATEFUL_PROPERTIES
    hover: STATEFUL_PROPERTIES
    active: STATEFUL_PROPERTIES
    disabled: STATEFUL_PROPERTIES
}

const BUTTON_VARIANTS: Record<ENUM_BUTTON_VARIANTS, STRUCTURE_BUTTON_VARIANT> = {
    DARK_PURPLE_PRIMARY: {
        normal: {
            bg: "darkPurple.100",
            borderColor: "darkPurple.100",
            color: "white",
        },
        hover: {
            bg: "blue.100",
            borderColor: "blue.100",
            color: "white",
        },
        disabled: {
            bg: "bluePurple.60",
            borderColor: "bluePurple.60",
            color: "white",
        },
        active: {
            bg: "blue.80",
            borderColor: "blue.80",
            color: "white",
        },
    },
    DARK_PURPLE_BORDERLESS: {
        normal: {
            bg: "transparent",
            borderColor: "transparent",
            color: "darkPurple.100",
        },
        hover: {
            bg: "transparent",
            borderColor: "transparent",
            color: "blue.100",
        },
        disabled: {
            bg: "transparent",
            borderColor: "transparent",
            color: "bluePurple.50",
        },
        active: {
            bg: "darkPurple.100",
            borderColor: "darkPurple.100",
            color: "blue.70",
        },
    },
    WHITE: {
        normal: {
            bg: "white",
            borderColor: "darkPurple.100",
            color: "darkPurple.100",
        },
        hover: {
            bg: "blue.100",
            borderColor: "blue.100",
            color: "white",
        },
        disabled: {
            bg: "white",
            borderColor: "bluePurple.50",
            color: "bluePurple.50",
        },
        active: {
            bg: "blue.70",
            borderColor: "blue.70",
            color: "white",
        },
    },
    WHITE_BORDERLESS: {
        normal: {
            bg: "transparent",
            borderColor: "transparent",
            color: "darkPurple.100",
        },
        hover: {
            bg: "white",
            borderColor: "white",
            color: "blue.100",
        },
        disabled: {
            bg: "transparent",
            borderColor: "transparent",
            color: "bluePurple.50",
        },
        active: {
            bg: "white",
            borderColor: "white",
            color: "blue.70",
        },
    },
    SECONDARY_OUTLINE: {
        normal: {
            bg: "transparent",
            borderColor: "bluePurple.20",
            color: "bluePurple.100",
        },
        active: {
            bg: "bluePurple.20",
            borderColor: "bluePurple.70",
            color: "bluePurple.100",
        },
        hover: {
            bg: "bluePurple.10",
            borderColor: "bluePurple.70",
            color: "bluePurple.100",
        },
        disabled: {
            bg: "white",
            borderColor: "bluePurple.20",
            color: "bluePurple.20",
        },
    },
    BLUE_PRIMARY: {
        normal: {
            bg: "blue.100",
            borderColor: "blue.100",
            color: "white",
        },
        hover: {
            bg: "blue.70",
            borderColor: "blue.70",
            color: "white",
        },
        disabled: {
            bg: "bluePurple.60",
            borderColor: "bluePurple.60",
            color: "white",
        },
        active: {
            bg: "blue.80",
            borderColor: "blue.80",
            color: "white",
        },
    },
    SECONDARY_GHOST: {
        normal: {
            bg: "transparent",
            borderColor: "transparent",
            color: "grey.90",
        },
        active: {
            bg: "transparent",
            borderColor: "transparent",
            color: "darkblue.70",
        },
        hover: {
            bg: "transparent",
            borderColor: "transparent",
            color: "blue.100",
        },
        disabled: {
            bg: "transparent",
            borderColor: "transparent",
            color: "bluePurple.50",
        },
    },
}

export default BUTTON_VARIANTS
