export default function MarketUp({ size, color = "#1B881F" }: { size?: number; color?: string }) {
    return (
        <svg width={size} height={size} viewBox="0 0 18 18" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path
                d="M2.55 13.5L1.5 12.45L7.05 6.8625L10.05 9.8625L13.95 6H12V4.5H16.5V9H15V7.05L10.05 12L7.05 9L2.55 13.5Z"
                fill={color}
            />
        </svg>
    )
}
