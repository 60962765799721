export default function Hamburger({ size = 24, color = "white" }: { size?: number; color?: string }) {
    return (
        <svg width={size} viewBox="0 0 20 21" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path
                d="M2.5 5.5H17.5V7.16667H2.5V5.5ZM2.5 9.66667H17.5V11.3333H2.5V9.66667ZM2.5 13.8333H17.5V15.5H2.5V13.8333Z"
                fill={color}
            />
        </svg>
    )
}
