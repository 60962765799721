import { Box, TableColumnHeaderProps, Text, Th } from "@chakra-ui/react"
import ArrowsVert from "assets/svgs/arrows/arrows-vert"

type Props = {
    text: string
    onClick: () => void
} & TableColumnHeaderProps

export default function ThSorting({ text, onClick, ...rest }: Props) {
    return (
        <Th {...rest}>
            <Text whiteSpace={"pre-wrap"} display="inline-block" color="grey.70" verticalAlign={"middle"} mr="0.5rem">
                {text}
            </Text>
            <Box
                onClick={onClick}
                height={"24px"}
                width={"24px"}
                borderRadius="4px"
                display="inline-block"
                verticalAlign={"middle"}
                pos={"relative"}
                cursor="pointer"
                _hover={{ bgColor: "bluePurple.10" }}
                _active={{ bgColor: "bluePurple.20" }}
            >
                <Box pos={"absolute"} top={"50%"} left={"50%"} transform={"translate(-50%, -50%)"}>
                    <ArrowsVert size={18} color="#48484A" />
                </Box>
            </Box>
        </Th>
    )
}
