type Props = {
    width: number
    color: string
}

export default function Lock({ width, color }: Props) {
    return (
        <svg
            width={width}
            height={(20 / 21) * width}
            viewBox="0 0 20 21"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
        >
            <path
                d="M5.00016 18.5308C4.54183 18.5308 4.14947 18.3676 3.82308 18.0412C3.49669 17.7148 3.3335 17.3224 3.3335 16.8641V8.53076C3.3335 8.07243 3.49669 7.68007 3.82308 7.35368C4.14947 7.02729 4.54183 6.86409 5.00016 6.86409H5.8335V5.19743C5.8335 4.04465 6.23975 3.06201 7.05225 2.24951C7.86475 1.43701 8.84738 1.03076 10.0002 1.03076C11.1529 1.03076 12.1356 1.43701 12.9481 2.24951C13.7606 3.06201 14.1668 4.04465 14.1668 5.19743V6.86409H15.0002C15.4585 6.86409 15.8509 7.02729 16.1772 7.35368C16.5036 7.68007 16.6668 8.07243 16.6668 8.53076V16.8641C16.6668 17.3224 16.5036 17.7148 16.1772 18.0412C15.8509 18.3676 15.4585 18.5308 15.0002 18.5308H5.00016ZM5.00016 16.8641H15.0002V8.53076H5.00016V16.8641ZM10.0002 14.3641C10.4585 14.3641 10.8509 14.2009 11.1772 13.8745C11.5036 13.5481 11.6668 13.1558 11.6668 12.6974C11.6668 12.2391 11.5036 11.8467 11.1772 11.5203C10.8509 11.194 10.4585 11.0308 10.0002 11.0308C9.54183 11.0308 9.14947 11.194 8.82308 11.5203C8.49669 11.8467 8.3335 12.2391 8.3335 12.6974C8.3335 13.1558 8.49669 13.5481 8.82308 13.8745C9.14947 14.2009 9.54183 14.3641 10.0002 14.3641ZM7.50016 6.86409H12.5002V5.19743C12.5002 4.50298 12.2571 3.91271 11.771 3.42659C11.2849 2.94048 10.6946 2.69743 10.0002 2.69743C9.30572 2.69743 8.71544 2.94048 8.22933 3.42659C7.74322 3.91271 7.50016 4.50298 7.50016 5.19743V6.86409Z"
                fill={color}
            />
        </svg>
    )
}
