import { Navigate, Outlet } from "react-router-dom"
import { ROUTES } from "./routes"
import { isUserAuthenticated } from "@redux/auth/slice"

const PrivateRoutes = () => {
    const isAuthenticated = isUserAuthenticated()

    if (!isAuthenticated) {
        return <Navigate to={ROUTES.LANDING} />
    }

    return <Outlet />
}

export default PrivateRoutes
