export default function ArrowsVertSlanted({ size = 24, color = "white" }: { size?: number; color?: string }) {
    return (
        <svg width={size} height={size} viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
            <g id="swap_vert_FILL0_wght400_GRAD0_opsz24 1">
                <path
                    id="Vector"
                    d="M8 13.0061V5.8311L5.425 8.4061L4 7.0061L9 2.0061L14 7.0061L12.575 8.4061L10 5.8311V13.0061H8ZM15 22.0061L10 17.0061L11.425 15.6061L14 18.1811V11.0061H16V18.1811L18.575 15.6061L20 17.0061L15 22.0061Z"
                    fill={color}
                />
            </g>
        </svg>
    )
}
