import { Box, Link, Text } from "@chakra-ui/react"
import Bell from "assets/svgs/common/bell"
import Close from "assets/svgs/common/close"
import Gear from "assets/svgs/common/gear"
import Hamburger from "assets/svgs/common/hamburger"
import HelpCircle from "assets/svgs/help-circle"
import LogoFull from "assets/svgs/netcoins/logo-full"
import User from "assets/svgs/user"
import { NavButton, SIDE_BAR_ROUTES } from "components/sidebar"
import { useState } from "react"

const HEADER_ROUTES: NavButton[] = [
    { label: "Help", path: "/help", icon: (color: string, size?: number) => <HelpCircle color={color} size={size} /> },
    {
        label: "Notifications",
        path: "/notifications",
        icon: (color: string, size?: number) => <Bell color={color} size={size} />,
    },
    {
        label: "Settings",
        path: "/settings",
        icon: (color: string, size?: number) => <Gear color={color} size={size} />,
    },
    { label: "Account", path: "/account", icon: (color: string, size?: number) => <User color={color} size={size} /> },
]

const HEADER_HEIGHT = 80

export default function HeaderDashboard() {
    const [dropdownOpen, setDropdownOpen] = useState(false)

    return (
        <Box pos="relative">
            <Box
                display="flex"
                alignItems="center"
                justifyContent={"space-between"}
                w="full"
                bg="darkPurple.100"
                height={HEADER_HEIGHT + "px"}
                px="1.5rem"
            >
                <Link href="/dashboard">
                    <LogoFull color="white" size={248} />
                </Link>
                <Box display={{ base: "none", sm: "flex" }} alignItems="center" columnGap="1.5rem">
                    {HEADER_ROUTES.map((route: NavButton) => (
                        <Link key={route.path} href={route.path}>
                            {route.icon("white")}
                        </Link>
                    ))}
                </Box>
                <Box
                    onClick={() => setDropdownOpen(!dropdownOpen)}
                    display={{ base: "inline-block", sm: "none" }}
                    cursor="pointer"
                >
                    {dropdownOpen ? <Close size={24} color="white" /> : <Hamburger size={24} color="white" />}
                </Box>
            </Box>
            <Box
                display={{ base: dropdownOpen ? "block" : "none", sm: "none" }}
                h="fit-content"
                bgColor="white"
                position="absolute"
                top={HEADER_HEIGHT + "px"}
                left="0"
                right="0"
                bottom="0"
                zIndex={10}
            >
                <Box display="flex" flexDir={"column"} rowGap="1.5rem" p="1.5rem" borderBottom="1px solid #E5E5EA">
                    {SIDE_BAR_ROUTES.map((route: NavButton) => (
                        <Link
                            key={route.path}
                            _hover={{
                                bgColor: location.pathname === `/${route.path}` ? "bluePurple.100" : "blue.10",
                            }}
                            href={route.path}
                            display="flex"
                            alignItems="center"
                            columnGap="1.5rem"
                            p="0.5rem 0.75rem"
                            borderRadius="4px"
                            bgColor={location.pathname === `/${route.path}` ? "bluePurple.100" : "white"}
                        >
                            {route.icon(location.pathname === `/${route.path}` ? "white" : "#07072E")}
                            <Text
                                textStyle="ManropeMedium"
                                fontWeight="600"
                                color={location.pathname === `/${route.path}` ? "white" : "bluePurple.100"}
                            >
                                {route.label}
                            </Text>
                        </Link>
                    ))}
                </Box>
                <Box display="flex" flexDir={"column"} rowGap="1rem" p="1.5rem">
                    {HEADER_ROUTES.map((route: NavButton) => (
                        <Link
                            key={route.path}
                            _hover={{
                                bgColor: "bluePurple.10",
                            }}
                            href={route.path}
                            display="flex"
                            alignItems="center"
                            columnGap="1.5rem"
                            p="0.5rem 0.75rem"
                            borderRadius="4px"
                            bgColor={location.pathname === `/${route.path}` ? "bluePurple.60" : "white"}
                        >
                            {route.icon(location.pathname === `/${route.path}` ? "blue.70" : "#636366", 18)}
                            <Text
                                textStyle="ManropeSubtitleSmall"
                                color={location.pathname === `/${route.path}` ? "blue.70" : "grey.70"}
                            >
                                {route.label}
                            </Text>
                        </Link>
                    ))}
                </Box>
            </Box>
        </Box>
    )
}
