import { Box, Text, useTheme } from "@chakra-ui/react"
import Alert from "@assets/svgs/common/alert"

export default function ErrorMessage({ children, color }: { children?: string; color?: string }) {
    const theme = useTheme()

    return (
        <Box display="flex" columnGap="0.5rem" alignItems="flex-start" mt="0.5rem">
            {children && (
                <>
                    <Box h="14px" mt="1px" width={"14px"}>
                        <Alert width={14} color={theme.colors.alert.error} />
                    </Box>
                    <Text mt="0" color={color ?? "black"} textStyle="InterTiny">
                        {children}
                    </Text>
                </>
            )}
        </Box>
    )
}
