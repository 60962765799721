// Centralized route segments for easier lookup and debugging
export const ROUTE_SEGMENTS = {
    ROOT: "/",
    DASHBOARD: "dashboard",
    LANDING: "landing",
    TWO_FA: "twofa",
    TRADE: "trade",
    TRANSFER: "transfer",
    NETCOINS_PAY: "netcoins-pay",
    ONBOARDING: "onboarding",
}

// Compose routes using segments:
export const ROUTES = {
    DASHBOARD: `/${ROUTE_SEGMENTS.DASHBOARD}`,
    LANDING: `/${ROUTE_SEGMENTS.LANDING}`,
    TWO_FA: `/${ROUTE_SEGMENTS.TWO_FA}`,
    ONBOARDING: `/${ROUTE_SEGMENTS.ONBOARDING}`,
    DASHBOARD_TRADE: `/${ROUTE_SEGMENTS.DASHBOARD}/${ROUTE_SEGMENTS.TRADE}`,
    DASHBOARD_TRANSFER: `/${ROUTE_SEGMENTS.DASHBOARD}/${ROUTE_SEGMENTS.TRANSFER}`,
    DASHBOARD_NETCOINS_PAY: `/${ROUTE_SEGMENTS.DASHBOARD}/${ROUTE_SEGMENTS.NETCOINS_PAY}`,
}
