export default function Bell({ size = 24, color = "white" }: { size?: number; color?: string }) {
    return (
        <svg width={size} height={size} viewBox="0 0 18 18" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path
                d="M12 12.7561H5.25V7.8811C5.25 6.0061 6.75 4.5061 8.625 4.5061C10.5 4.5061 12 6.0061 12 7.8811V12.7561ZM13.5 12.0061V7.8811C13.5 5.5786 11.895 3.6511 9.75 3.1411V2.6311C9.75 2.33273 9.63147 2.04659 9.4205 1.83561C9.20952 1.62463 8.92337 1.5061 8.625 1.5061C8.32663 1.5061 8.04048 1.62463 7.8295 1.83561C7.61853 2.04659 7.5 2.33273 7.5 2.6311V3.1411C5.3475 3.6511 3.75 5.5786 3.75 7.8811V12.0061L2.25 13.5061V14.2561H15V13.5061L13.5 12.0061ZM8.625 16.5061C9.02282 16.5061 9.40436 16.3481 9.68566 16.0668C9.96696 15.7855 10.125 15.4039 10.125 15.0061H7.125C7.125 15.4039 7.28304 15.7855 7.56434 16.0668C7.84564 16.3481 8.22718 16.5061 8.625 16.5061Z"
                fill={color}
            />
        </svg>
    )
}
