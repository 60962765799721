import React from "react"
import { Box, FormControl } from "@chakra-ui/react"
import SideDrawerContainer from "components/sideDrawerContainer"
import StandardButton from "components/ui/buttons/standard"
import { useTranslation } from "react-i18next"
import { ENUM_BUTTON_VARIANTS } from "components/ui/buttons/standard/types"
import { AssetDetails } from "@redux/assetsDetails/types"

type Props = {
    isOpen: boolean
    toggleSideDrawerOpen: () => void
    coin: AssetDetails | null
    openingTab?: "buy" | "sell"
}

export default function DrawerCoinTrade({ isOpen, toggleSideDrawerOpen, coin, openingTab }: Props) {
    const { t } = useTranslation(["trade", "common"])

    const [tab, setTab] = React.useState("buy")

    // whenever the parent component changes the openingTab, we need to update the tab state
    React.useEffect(() => {
        if (openingTab) {
            setTab(openingTab)
        }
    }, [openingTab])

    return (
        <SideDrawerContainer isOpen={isOpen} toggleSideDrawerOpen={toggleSideDrawerOpen}>
            <FormControl display="flex" flex={1} flexDir="column">
                <Box display="flex" flex={1}></Box>
                <StandardButton variant={ENUM_BUTTON_VARIANTS.DARK_PURPLE_PRIMARY} type="submit">
                    {t("continue", { ns: "common" })}
                </StandardButton>
            </FormControl>
        </SideDrawerContainer>
    )
}
