import React from "react"
import { Box, useRadio, useRadioGroup, FormControl, FormLabel, Text, FormControlProps } from "@chakra-ui/react"
import { useController, UseControllerProps } from "react-hook-form"
import { FIELD_SIZE, Option } from "../types"
import ErrorMessage from "../errorMessage"

type Props = {
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    options: Option<any>[]
    isHorizontal: boolean
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    defaultOption?: Option<any>
    label?: string
    size?: "sm" | "md" | "lg"
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
} & UseControllerProps<any> &
    FormControlProps

type CardProps = {
    children: React.ReactNode
    isDynamicHeight?: boolean
    size?: "sm" | "md" | "lg"
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    [radioProps: string]: any
}

const CIRCLE_SIZES = {
    sm: { outer: 10, inner: 4 },
    md: { outer: 16, inner: 6 },
    lg: { outer: 20, inner: 8 },
}

function RadioCard({ children, size, isDynamicHeight, ...radioProps }: CardProps) {
    const { getInputProps, getCheckboxProps } = useRadio(radioProps)

    const input = getInputProps()
    const checkbox = getCheckboxProps()

    return (
        <Box as="label" display="flex" flexGrow={1}>
            <input {...input} />
            <Box
                {...checkbox}
                display="flex"
                flexGrow={1}
                position="relative"
                height={isDynamicHeight ? "auto" : `${FIELD_SIZE[size ?? "lg"]}px`}
                cursor="pointer"
                borderRadius="md"
                bg="grey.10"
                borderWidth="4px"
                borderColor="transparent"
                _checked={{
                    bg: "blue.pale",
                    color: "black",
                }}
                _hover={{
                    bg: "blue.100",
                    color: "white",
                    "& div": {
                        borderColor: "white",
                        "> div": {
                            backgroundColor: "white",
                        },
                    },
                }}
                px={"1rem"}
                py={3}
                alignItems="center"
            >
                {children}
                <Box
                    {...checkbox}
                    borderWidth="2px"
                    borderColor={"black"}
                    _checked={{
                        borderColor: "blue.100",
                    }}
                    height={`${CIRCLE_SIZES[size ?? "lg"].outer}px`}
                    width={`${CIRCLE_SIZES[size ?? "lg"].outer}px`}
                    borderRadius="full"
                    pos="absolute"
                    top={isDynamicHeight ? 4 : "50%"} // 4 because 3 for the padding and 1 for the text line-height being 150%
                    transform={isDynamicHeight ? undefined : "translateY(-50%)"}
                    display="flex"
                    justifyContent={"center"}
                    alignItems={"center"}
                >
                    <Box
                        {...checkbox}
                        display="none"
                        _checked={{
                            display: "block",
                        }}
                        height={`${CIRCLE_SIZES[size ?? "lg"].inner}px`}
                        width={`${CIRCLE_SIZES[size ?? "lg"].inner}px`}
                        borderRadius="full"
                        bg="blue.100"
                    />
                </Box>
            </Box>
        </Box>
    )
}

export default function RadioGroup(props: Props) {
    const {
        options,
        name: propsName,
        defaultOption: defaultValue,
        isHorizontal,
        control,
        rules,
        label,
        size = "lg",
        ...rest
    } = props

    const {
        field,
        fieldState: { invalid, error },
        // eslint-disable-next-line @typescript-eslint/no-explicit-any
    } = useController<any>({
        name: propsName,
        control,
        rules,
    })

    const { getRootProps, getRadioProps } = useRadioGroup({
        name: propsName,
        onChange: (val) => {
            const option = options.find((o) => o.value === val)
            if (option) {
                field.onChange(option)
            }
        },
        value: field.value?.value,
        ...(defaultValue && { defaultValue: defaultValue.value }),
    })

    const group = getRootProps()

    return (
        <FormControl isInvalid={invalid} {...rest}>
            {label && (
                <FormLabel fontSize={14} lineHeight={"100%"} htmlFor={propsName}>
                    {label}
                </FormLabel>
            )}
            <Box
                {...group}
                display="flex"
                flexDir={isHorizontal ? "row" : "column"}
                data-testid={`radio-group-${propsName}`}
                columnGap={isHorizontal ? "1.5rem" : 0}
                w="full"
            >
                {options.map((value) => {
                    const radio = getRadioProps({ value: value.value })
                    return (
                        <RadioCard key={value.value} size={size} {...radio} isDynamicHeight={!!value.desc}>
                            <Box display="flex" flexDirection="column" rowGap="0.75rem" ml={8}>
                                <Text textStyle="InterRegular">{value.label}</Text>
                                {value.desc && <Text textStyle="InterTiny">{value.desc}</Text>}
                            </Box>
                        </RadioCard>
                    )
                })}
            </Box>
            {!!error && <ErrorMessage>{error?.message}</ErrorMessage>}
        </FormControl>
    )
}
