import StandardButton from "@/components/ui/buttons/standard"
import { ENUM_BUTTON_VARIANTS } from "@/components/ui/buttons/standard/types"
import AmplitudeClient from "@/sdks/amplitude"
import { formatDefaultLocationValues } from "@/util/optionsFormatted"
import { formatPostalCode } from "@/util/stringFormatting"
import { Box, Text } from "@chakra-ui/react"
import AddressForm from "@components/forms/address"
import DetailsTypeCard from "@components/ui/breadcrumbs/detailsType"
import React from "react"
import { useForm } from "react-hook-form"
import { useTranslation } from "react-i18next"
import { SubScreenProps } from ".."
import { AccountType, REGISTRATION_STEP, REG_SCREENS } from "../types"

type FormData = {
    street_number: string
    street_name: string
    building_address: string
    city: string
    region: { value: string; label: string }
    country: { value: string; label: string }
    postal_code: string
}

/*
  Note that this screen is designed for CA or USA only.
  Be aware of this if one day someone decides to include all countries.
  Namely, the postal code and region fields will have to be reworked.
*/

export default function WhereLive({ submitSubForm, userObj, setUserObj, isLoading }: SubScreenProps) {
    const { handleSubmit, control, watch, getValues, setValue } = useForm<FormData>(formatDefaultValues())
    const { t } = useTranslation(["onboarding", "common"])

    const [initialLoadCount, setInitialLoadCount] = React.useState(0)

    function formatDefaultValues() {
        // If the user is an individual, the country and region (data obj root level) are forced to be the same as the identity address
        const _country =
            userObj?.type === AccountType.INDIVIDUAL ? userObj?.country : userObj?.identity.address?.country
        const _region = userObj?.type === AccountType.INDIVIDUAL ? userObj?.region : userObj?.identity.address?.region
        if (_country && _region) {
            const countryAndRegion = formatDefaultLocationValues(_country, _region)
            if (countryAndRegion) {
                const addressStr = userObj?.identity.address?.street_address?.split(" ")
                const streetNumber = addressStr?.length ? addressStr.shift() : undefined
                // skips the first element cause it was shifted above
                const streetName = addressStr?.length ? addressStr.join(" ") : undefined
                return {
                    defaultValues: {
                        country: countryAndRegion?.country,
                        region: countryAndRegion?.region,
                        street_number: streetNumber || undefined,
                        street_name: streetName || undefined,
                        building_address: userObj?.identity.address?.building_address || undefined,
                        city: userObj?.identity.address?.city || undefined,
                        postal_code: userObj?.identity.address?.postal_code || undefined,
                    },
                }
            }
        }
        return undefined
    }

    const isCanada = React.useMemo(() => watch("country")?.value === "CA", [watch("country")?.value])

    // Forces region to clear on country change
    React.useEffect(() => {
        // ... this is dumb but here we are
        // It waits for an intial load and then possibly again if the defaultValue gets set
        if ((userObj?.country && initialLoadCount > 1) || !userObj?.country) {
            // @ts-expect-error Set value as null
            setValue("region", null)
        } else {
            setInitialLoadCount((prev) => prev + 1)
        }
    }, [getValues("country"), isCanada])

    const onSubmit = async (values: FormData) => {
        const {
            street_number: streetNumber,
            street_name: streetName,
            building_address: buildingAddress,
            city,
            region,
            country,
            postal_code: postalCode,
        } = values

        const payload = {
            address: {
                street_address: streetNumber.trim().replace(/\s/g, "") + " " + streetName,
                building_address: buildingAddress?.trim(),
                municipality: city.trim(), // server accepts 'municipality' instead of 'city' which its changed to later
                region: region.value,
                country: country.value,
                postal_code: formatPostalCode(country.value, postalCode),
            },
        }
        await submitSubForm("/api/v2/account/identity", payload, () => {
            setUserObj((prev) =>
                prev ? { ...prev, identity: { ...prev.identity, address: { ...payload.address, city } } } : prev
            )
            AmplitudeClient.logRegistrationEvent(REG_SCREENS.WhereLive, REGISTRATION_STEP.ADDRESS)
        })
    }

    return (
        <Box w="full">
            {userObj?.type === AccountType.BUSINESS && <DetailsTypeCard isPersonal />}
            <form onSubmit={handleSubmit(onSubmit)}>
                <Box display="flex" flexDir="column" rowGap="1.5rem">
                    <Text textStyle="InterTitle">{t("whereLive.whereDoYou")}</Text>
                    <AddressForm
                        control={control}
                        isCanada={isCanada}
                        areLocationsDisabled={userObj?.type === AccountType.INDIVIDUAL}
                    />
                    <StandardButton
                        data-testid="continue-button"
                        type="submit"
                        variant={ENUM_BUTTON_VARIANTS.DARK_PURPLE_PRIMARY}
                        mt="1.5rem"
                        isLoading={isLoading}
                    >
                        {t("continue", { ns: "common" })}
                    </StandardButton>
                </Box>
            </form>
        </Box>
    )
}
