export default function MarketDown({ size, color = "#C62828" }: { size?: number; color?: string }) {
    return (
        <svg width={size} viewBox="0 0 16 10" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path
                d="M11 9.5V8H12.95L9.05 4.1375L6.05 7.1375L0.5 1.55L1.55 0.5L6.05 5L9.05 2L14 6.95V5H15.5V9.5H11Z"
                fill={color}
            />
        </svg>
    )
}
