import { fetchBaseQuery } from "@reduxjs/toolkit/query/react"
import { TApiVersion } from "./types"
import { RootState } from "@/store/reducer"
import { selectAccessToken } from "@redux/auth/selectors"

export const baseQuery = (path: string, apiVersion: TApiVersion = "v2") => {
    return fetchBaseQuery({
        baseUrl: `${process.env.REACT_APP_BASE_URL}/api/${apiVersion}/${path}`,
        prepareHeaders: (headers, { getState }) => {
            const state = getState() as RootState
            headers.set("Authorization", "Bearer " + selectAccessToken(state))
            return headers
        },
    })
}
