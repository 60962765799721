import { createApi } from "@reduxjs/toolkit/query/react"
import { API_SLICE_NAME, API_PATH, TAG_TYPES } from "./const"
import { baseQuery } from "@util/RTKApi"
import { AssetDetailsFull } from "./types"

export const assetDetailsApi = createApi({
    reducerPath: API_SLICE_NAME,
    baseQuery: baseQuery(API_PATH.ASSETS_DETAILS),
    tagTypes: [TAG_TYPES.ASSET_DETAILS],
    endpoints: (builder) => ({
        getAssetsDetails: builder.query<Record<string, AssetDetailsFull>, void>({
            query: () => "",
        }),
    }),
})

export const { useGetAssetsDetailsQuery } = assetDetailsApi
