import React from "react"
import { FramerBox } from "components/motion"

type Props = {
    bgWidth: string
    streakOpacity: number
    transitionTime: number
}

export default function AnimatedBackground({ bgWidth, streakOpacity, transitionTime }: Props) {
    return (
        <FramerBox
            display="flex"
            h="full"
            bg="darkPurple.100"
            position={"absolute"}
            top={0}
            left={0}
            zIndex={-1}
            initial={{ width: "100%" }}
            animate={{ width: bgWidth }}
            // @ts-expect-errors stated by Chakra UI with framer-motion
            transition={{ duration: transitionTime }}
        >
            <FramerBox
                display="flex"
                bgGradient="linear-gradient(172deg, darkPurple.100 56.28%, #39377B 79.62%, blue.100 101.98%)"
                w="full"
                h="full"
                initial={{ opacity: 0 }}
                animate={{ opacity: streakOpacity }}
                // @ts-expect-errors stated by Chakra UI with framer-motion
                transition={{ duration: transitionTime === 0 ? 0 : 2.5 }}
            />
        </FramerBox>
    )
}
