import { Box, Text } from "@chakra-ui/react"
import useFetchWrapper, { Method } from "@hooks/useFetchWrapper"
import React, { useEffect } from "react"
// eslint-disable-next-line import/default
import Chart from "react-apexcharts"
import { MARKET_HISTORY_PERIODS, MarketHistoryPoint, MarketHistoryPromisesResponse } from "./types"

type Props = {
    asset?: string
}

export default function MarketGraph({ asset }: Props) {
    const { fetchWrapper } = useFetchWrapper()

    const [marketHistory, setMarketHistory] = React.useState<Record<MARKET_HISTORY_PERIODS, MarketHistoryPoint[]>>()
    const [type, setType] = React.useState<"line" | "candlestick">("line")
    const [period, setPeriod] = React.useState<MARKET_HISTORY_PERIODS>(MARKET_HISTORY_PERIODS["1W"])
    const [options, setOptions] = React.useState<ApexCharts.ApexOptions>()
    const [series, setSeries] = React.useState<ApexAxisChartSeries | ApexNonAxisChartSeries>()

    useEffect(() => {
        if (asset) {
            fetchMarketHistory(asset)
        }
    }, [asset])

    // change xaxis on data change
    useEffect(() => {
        const data = marketHistory?.[period]
        if (data) {
            const dataPoints = data.map((_data) => Number(_data.buy_price))
            const timePoints = data.map((_data) => new Date(_data.created_at).toLocaleDateString())
            setOptions({ ...options, xaxis: { categories: timePoints } })
            setSeries([{ name: "market-details", data: dataPoints }])
        }
    }, [period, marketHistory])

    async function fetchMarketHistory(asset: string) {
        // TODO get counter_asset from store
        await Promise.all(
            Object.values(MARKET_HISTORY_PERIODS).map((period) =>
                fetchWrapper(
                    `/api/v2/markets/history?asset=${asset.toLowerCase()}&counter_asset=cad&period=${period}`,
                    Method.GET
                ).then((res: MarketHistoryPoint[]) => {
                    return { period, data: res }
                })
            )
        ).then((responses: MarketHistoryPromisesResponse[]) => {
            setMarketHistory(
                responses.reduce(
                    (acc, curr) => ({ ...acc, [curr.period]: curr.data }),
                    {} as Record<MARKET_HISTORY_PERIODS, MarketHistoryPoint[]>
                )
            )
        })
    }

    return (
        <Box
            position="relative"
            borderTopColor="grey.20"
            borderTopWidth={1}
            borderBottomColor="grey.20"
            borderBottomWidth={1}
            py="3rem"
        >
            <Box
                display="flex"
                justifyContent={"space-between"}
                flexDir={{ base: "column", md: "row" }}
                alignItems={{ base: "center", md: undefined }}
                rowGap="1rem"
            >
                <Box display="flex" p="0.25rem" borderRadius={"0.25rem"} borderWidth={1} borderColor={"grey.20"}>
                    <Box
                        onClick={() => setType("line")}
                        width="7rem"
                        py="0.127rem"
                        bgColor={type === "line" ? "grey.10" : "white"}
                        cursor="pointer"
                        borderRadius="3px"
                    >
                        <Text textAlign={"center"} color={type === "line" ? "black" : "grey.40"}>
                            Line
                        </Text>
                    </Box>
                    <Box
                        onClick={() => setType("candlestick")}
                        width="7rem"
                        py="0.125rem"
                        bgColor={type === "candlestick" ? "grey.10" : "white"}
                        cursor="pointer"
                        borderRadius="3px"
                    >
                        <Text textAlign="center" color={type === "candlestick" ? "black" : "grey.40"}>
                            Candle
                        </Text>
                    </Box>
                </Box>
                <Box display="flex" p="0.5rem" borderRadius={"0.25rem"} mb="1rem">
                    {Object.values(MARKET_HISTORY_PERIODS).map((_period) => (
                        <Box
                            key={_period}
                            onClick={() => setPeriod(_period)}
                            width="5rem"
                            py="0.25rem"
                            cursor="pointer"
                            bgColor={period === _period ? "grey.20" : "white"}
                            borderRadius="1rem"
                        >
                            <Text textAlign="center" color={period === _period ? "black" : "grey.40"}>
                                {_period}
                            </Text>
                        </Box>
                    ))}
                </Box>
            </Box>
            {options && series && marketHistory && (
                <Chart options={options} series={series} type={type} width={"100%"} height={400} />
            )}
        </Box>
    )
}
