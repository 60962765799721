import { Disclaimer, Survey } from "@screens/onboarding/surveys/types"
import CountriesJSON from "../../json/countries.json"
import { AccountType } from "../onboarding/types"

export type Address = Partial<{
    street_address: string
    building_address: string
    city: string
    region: string
    country: string
    postal_code: string
}>

export enum IdentityCitizenship {
    US_CITIZEN = "us_citizen",
    US_RESIDENT = "us_resident",
    CA_CITIZEN = "citizen",
    CA_RESIDENT = "resident",
    PENDING = "pending",
    NONE = "na",
}

export type Identity = {
    first_name?: string
    last_name?: string
    dob?: string
    phone?: string
    occupation?: string
    occupation_type?: string
    citizenship?: IdentityCitizenship | keyof typeof CountriesJSON
    id_number?: boolean
    address: Address
}

export type Business = Partial<{
    legal_name: string
    alt_name: string
    corporate_role: string
    date_incorporated: Date
    is_money_service: 0 | 1
    government_id: string
    industry: string
    reg_type: string
    projected_quarter: {
        amount: number
        currency: "CAD" | "USD"
    }
    website: string
    address: Address
}>

export type VertificationInfoRes = {
    verified: boolean
    email: boolean
    phone: {
        number: boolean
        is_verified: boolean
        requires_sms_verification: boolean
    }
    status: string
    identity: boolean
    idv: null | boolean
    ekyc: null | boolean
    screening: null | boolean
    disclaimers: Record<string, Disclaimer>
    surveys: Survey[]
}

export type AccountRes = {
    uuid: string
    account_number: string
    registered_at: Date
    type: AccountType
    country?: string
    region?: string
    email: string
    identity: Identity
    business: Business
    verification: VertificationInfoRes
    disclaimers: Record<string, Disclaimer>
    surveys: Survey[]
    features: {
        affiliate_program?: {
            affiliated_with_code?: string
        }
        interac?: boolean
        ncpay: {
            accessible: boolean
            active: boolean
            must_migrate: boolean
            has_migrated: boolean
            supports_visa: boolean
            supports_mastercard: boolean
        }
    }
    onboarding_survey?: string
    onboarding_survey_details?: string
    tfa: boolean
    timezone: string
    userState: string
}
