import { Box, Flex, Link, SimpleGrid, Text, VStack, useBreakpointValue } from "@chakra-ui/react"
import React from "react"
import { TransactionTable, TransactionTableData } from "./TransactionDetails"

interface StatItemProps {
    label: string
    value: string
}

const StatItem: React.FC<StatItemProps> = ({ label, value }) => (
    <VStack align="flex-start" spacing={1}>
        <Text textStyle="ManropeSemiBold" color="grey.60">
            {label}
        </Text>
        <Text textStyle="ManropeSemiBold">{value}</Text>
    </VStack>
)

interface MarketStats {
    volume24h: string
    low24h: string
    high24h: string
    marketCap: string
    maxSupply: string
    circulatingSupply: string
}

interface AboutAsset {
    description: string
    learnMoreLink: string
}

interface AssetStatsProps {
    assetName: string
    marketStats: MarketStats
    aboutAsset: AboutAsset
    transactions?: TransactionTableData[]
}

const AboutSection: React.FC<{ assetName: string; aboutAsset: AboutAsset }> = ({ assetName, aboutAsset }) => (
    <Box>
        <Text mb={4} textStyle="ManropeBold">
            About {assetName}
        </Text>
        <Text textStyle="InterTiny">{aboutAsset.description}</Text>
        <Link
            textStyle="ManropeSubtitleSmall"
            color="blue.100"
            href={aboutAsset.learnMoreLink}
            mt={2}
            display="inline-block"
        >
            Learn more about {assetName}
        </Link>
    </Box>
)

const AssetStats: React.FC<AssetStatsProps> = ({ assetName, marketStats, aboutAsset, transactions }) => {
    const isLargerThanLg = useBreakpointValue({ base: false, md: false, lg: true })

    return (
        <Box p={6} maxWidth="100%">
            <Flex direction={isLargerThanLg ? "row" : "column"} justifyContent="space-between">
                <Flex direction="column" width={isLargerThanLg ? "45%" : "100%"}>
                    <Box mb={6}>
                        <Text mb={4} textStyle="ManropeBold">
                            {assetName} Market Statistics
                        </Text>
                        <SimpleGrid columns={[2, 2, 2, 3]} spacing={4}>
                            <StatItem label="24H Volume" value={marketStats.volume24h} />
                            <StatItem label="24H Low" value={marketStats.low24h} />
                            <StatItem label="24H High" value={marketStats.high24h} />
                            <StatItem label="Market Cap" value={marketStats.marketCap} />
                            <StatItem label="Max Supply" value={marketStats.maxSupply} />
                            <StatItem label="Circulating Supply" value={marketStats.circulatingSupply} />
                        </SimpleGrid>
                    </Box>
                    {isLargerThanLg && <AboutSection assetName={assetName} aboutAsset={aboutAsset} />}
                </Flex>
                {!isLargerThanLg && (
                    <Box>
                        <AboutSection assetName={assetName} aboutAsset={aboutAsset} />
                    </Box>
                )}
                {transactions && (
                    <Box width={isLargerThanLg ? "50%" : "100%"} mt={isLargerThanLg ? 0 : 6}>
                        <Text mb={4} textStyle="ManropeBold">
                            My Holding
                        </Text>
                        <TransactionTable transactionTableData={transactions} />
                    </Box>
                )}
            </Flex>
        </Box>
    )
}

export default AssetStats
