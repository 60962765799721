import { configureStore } from "@reduxjs/toolkit"
import appReducer from "./reducer"
import { assetDetailsApi } from "@redux/assetsDetails/apiSlice"

export const store = configureStore({
    reducer: appReducer,
    middleware: (getDefaultMiddleware) => {
        const middleware = getDefaultMiddleware().concat(assetDetailsApi.middleware)
        return middleware
    },
})
