import { Box, Link, Text } from "@chakra-ui/react"
import Chevron from "@assets/svgs/arrows/chevron"

export type BreadCrumbProps = {
    text: string
    onClick?: () => void
    hasPrevious?: boolean
}

export default function BreadCrumb({ hasPrevious = true, text, onClick }: BreadCrumbProps) {
    const Component = onClick ? Link : Box
    return (
        <Component display="flex" alignItems="center" onClick={onClick}>
            {hasPrevious && (
                <Box mr="0.5rem">
                    <Chevron size={12} color="#636366" />
                </Box>
            )}
            <Box py="0.5rem">
                <Text textStyle={"ManropeMedium"}>{text}</Text>
            </Box>
        </Component>
    )
}
