import { SolidVariant, SubtleVariant } from "./alerts"
import { checkboxTheme } from "./checkbox"

export const Components = {
    Alert: {
        // Toasts are considered Alerts under the hood
        variants: {
            subtle: SubtleVariant,
            solid: SolidVariant,
        },
    },
    Button: {
        sizes: {
            xl: {
                h: "56px",
                fontSize: "lg",
                px: "32px",
            },
        },
    },
    Checkbox: checkboxTheme,
}
