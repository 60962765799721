export default function FourBlock({ size = 24, color = "white" }: { size?: number; color?: string }) {
    return (
        <svg width={size} height={size} viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path
                d="M13 9.0061V3.0061H21V9.0061H13ZM3 13.0061V3.0061H11V13.0061H3ZM13 21.0061V11.0061H21V21.0061H13ZM3 21.0061V15.0061H11V21.0061H3ZM5 11.0061H9V5.0061H5V11.0061ZM15 19.0061H19V13.0061H15V19.0061ZM15 7.0061H19V5.0061H15V7.0061ZM5 19.0061H9V17.0061H5V19.0061Z"
                fill={color}
            />
        </svg>
    )
}
