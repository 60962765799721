export default function ArrowsVertSlanted({ size = 24, color = "white" }: { size?: number; color?: string }) {
    return (
        <svg width={size} height={size} viewBox="0 0 19 18" fill={color} xmlns="http://www.w3.org/2000/svg">
            <path
                d="M9.91687 13.6275L7.53937 11.25L6.48187 12.3075L9.91687 15.75L13.3594 12.3075L12.2944 11.25L9.91687 13.6275ZM9.91687 4.3725L12.2944 6.75L13.3519 5.6925L9.91687 2.25L6.47437 5.6925L7.53937 6.75L9.91687 4.3725Z"
                fill={color}
            />
        </svg>
    )
}
