import { Box } from "@chakra-ui/react"
import HeaderDashboard from "components/header/dashboard"
import Sidebar from "components/sidebar"
import { Outlet } from "react-router-dom"

export default function Dashboard() {
    return (
        <Box h="100vh" display="flex" flexDir="column">
            <HeaderDashboard />
            <Box display="flex" height={"calc(100vh - 80px)"}>
                <Sidebar />
                <Box overflowY="auto" height="100%" width="100%" display="block">
                    <Box
                        h="full"
                        maxWidth="1536px"
                        m="0 auto"
                        px={{ base: "1rem", sm: "1.5rem" }}
                        pt={{ base: "1rem", sm: "1.5rem" }}
                    >
                        <Outlet />
                    </Box>
                </Box>
            </Box>
        </Box>
    )
}
