import More from "@assets/svgs/common/more"
import {
    Box,
    Flex,
    IconButton,
    Tab,
    TabList,
    TabPanel,
    TabPanels,
    Tabs,
    Text,
    useBreakpointValue,
} from "@chakra-ui/react"
import StandardButton from "@components/ui/buttons/standard"
import { ENUM_BUTTON_VARIANTS } from "@components/ui/buttons/standard/types"
import { useState } from "react"

interface TransactionDetail {
    date: string
    type: string
    price: string
    amount: string
}

export interface TransactionTableData {
    label: string
    data: TransactionDetail[]
}

export interface TransactionTableProps {
    transactionTableData: TransactionTableData[]
}

export const TransactionTable: React.FC<TransactionTableProps> = ({ transactionTableData }) => {
    const [currentTabIndex, setCurrentTabIndex] = useState(0)
    const isMobile = useBreakpointValue({ base: true, md: false })
    const TableHeader = () => (
        <Flex borderTop="1px solid" borderBottom="2px solid" borderColor="grey.20" backgroundColor={"grey.10"} py={3}>
            {isMobile ? (
                <>
                    <Text textStyle="ManropeSubtitleSmall" flex={1} fontWeight="normal" color="grey.70">
                        Order Type
                    </Text>
                    <Text
                        textStyle="ManropeSubtitleSmall"
                        width="80px"
                        textAlign="right"
                        fontWeight="normal"
                        color="grey.70"
                    >
                        Amount
                    </Text>
                    <Box width="40px" />
                </>
            ) : (
                <>
                    <Text textStyle="ManropeSubtitleSmall" width="25%" fontWeight="normal" color="grey.70">
                        Date
                    </Text>
                    <Text textStyle="ManropeSubtitleSmall" width="25%" fontWeight="normal" color="grey.70">
                        Type
                    </Text>
                    <Text textStyle="ManropeSubtitleSmall" width="25%" fontWeight="normal" color="grey.70">
                        Price
                    </Text>
                    <Text textStyle="ManropeSubtitleSmall" width="25%" fontWeight="normal" color="grey.70">
                        Amount
                    </Text>
                    <Box width="40px" />
                </>
            )}
        </Flex>
    )

    const renderMobileTableContent = (data: TransactionDetail[]) => (
        <Box width="100%">
            <TableHeader />
            {data.map((tx, index) => (
                <Flex
                    key={index}
                    justify="space-between"
                    align="center"
                    py={3}
                    borderBottom="1px solid"
                    borderColor="grey.20"
                >
                    <Box flex={1}>
                        <Text textStyle="ManropeSubtitleSmall" color="grey.60">
                            {tx.date}
                        </Text>
                        <Text textStyle="ManropeSubtitleSmall">
                            {tx.type} at {tx.price}
                        </Text>
                    </Box>
                    <Text textStyle="ManropeSubtitleSmall" textAlign="right">
                        {tx.amount}
                    </Text>
                    <Box width="40px">
                        <IconButton aria-label="More options" icon={<More size={16} />} size="sm" variant="ghost" />
                    </Box>
                </Flex>
            ))}
        </Box>
    )

    const renderDesktopTableContent = (data: TransactionDetail[]) => (
        <Box width="100%">
            <TableHeader />
            {data.map((tx, index) => (
                <Flex key={index} py={3} borderBottom="1px solid" borderColor="grey.20" alignItems="center">
                    <Text color="grey.60" textStyle="ManropeSubtitleSmall" width="25%">
                        {tx.date}
                    </Text>
                    <Text textStyle="ManropeSubtitleSmall" width="25%">
                        {tx.type}
                    </Text>
                    <Text textStyle="ManropeSubtitleSmall" width="25%">
                        {tx.price}
                    </Text>
                    <Text textStyle="ManropeSubtitleSmall" width="25%">
                        {tx.amount}
                    </Text>
                    <Box width="40px">
                        <IconButton aria-label="More options" icon={<More size={16} />} size="sm" variant="ghost" />
                    </Box>
                </Flex>
            ))}
        </Box>
    )

    return (
        <Box width="100%">
            <Tabs index={currentTabIndex} onChange={(index) => setCurrentTabIndex(index)}>
                <TabList>
                    {transactionTableData.map((tab, index) => (
                        <Tab
                            textStyle="ManropeBold"
                            key={index}
                            flex={1}
                            _selected={{
                                borderBottom: "3px solid",
                                color: "black",
                                borderColor: "blue.100",
                            }}
                            borderRadius={0}
                        >
                            {tab.label}
                        </Tab>
                    ))}
                </TabList>
                <TabPanels>
                    {transactionTableData.map((tab, index) => (
                        <TabPanel key={index} padding={0}>
                            {isMobile ? renderMobileTableContent(tab.data) : renderDesktopTableContent(tab.data)}
                        </TabPanel>
                    ))}
                </TabPanels>
            </Tabs>
            <Flex justifyContent="center" mt={2}>
                <StandardButton
                    type="button"
                    variant={ENUM_BUTTON_VARIANTS.SECONDARY_OUTLINE}
                    textStyle="ManropeSemiBold"
                    size="sm"
                >
                    {`View all ${transactionTableData[currentTabIndex].label.toLowerCase()}`}
                </StandardButton>
            </Flex>
        </Box>
    )
}
