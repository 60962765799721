import React from "react"
import { Box } from "@chakra-ui/react"
import { AnimatePresence } from "framer-motion"
import { FramerBox } from "components/motion"
import Close from "assets/svgs/common/close"

type Props = {
    isOpen: boolean
    toggleSideDrawerOpen: () => void
    children: React.ReactNode
}

export const CONTAINER_WIDTH = 525
const HEADER_HEGIHT = 40

export default function SideDrawerContainer({ isOpen, children, toggleSideDrawerOpen }: Props) {
    return (
        <AnimatePresence>
            {isOpen && (
                <FramerBox
                    key="animate-sidedrawer-background"
                    display="flex"
                    h="full"
                    w="full"
                    bg="rgba(0, 0, 0, 0.30)"
                    position={"absolute"}
                    top={0}
                    right={0}
                    zIndex={10}
                    padding={"1.5rem"}
                    initial={{ opacity: 0 }}
                    animate={{ opacity: 1 }}
                    // @ts-expect-error stated by Chakra UI with framer-motion
                    transition={{ duration: 0.75 }}
                    exit={{ opacity: 0 }}
                >
                    <Box height="100%" width="100%" onClick={toggleSideDrawerOpen}></Box>
                </FramerBox>
            )}
            {isOpen && (
                <FramerBox
                    key="animate-sidedrawer"
                    display="flex"
                    h="full"
                    w="full"
                    bg="white"
                    position={"absolute"}
                    top={0}
                    right={0}
                    zIndex={10}
                    initial={{ width: 0 }}
                    animate={{ maxWidth: CONTAINER_WIDTH + "px", width: "100%" }}
                    // @ts-expect-error stated by Chakra UI with framer-motion
                    transition={{ duration: 0.75 }}
                    exit={{ width: 0, maxWidth: 0 }}
                >
                    <Box display="flex" flexDir="column" p="1.5rem" w="full">
                        <Box
                            onClick={toggleSideDrawerOpen}
                            h={HEADER_HEGIHT + "px"}
                            display="flex"
                            justifyContent={"flex-end"}
                            alignSelf={"flex-end"}
                            alignItems={"center"}
                            cursor="pointer"
                        >
                            <Close color="black" size={24} />
                        </Box>
                        <Box display="flex" flex={1}>
                            {children}
                        </Box>
                    </Box>
                </FramerBox>
            )}
        </AnimatePresence>
    )
}
